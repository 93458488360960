import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import Logo from "../../../assets/image/blackLogo.svg";
import { Loader2, RequestSuccessFail } from "components";
import GuarantorAcceptForm1 from "components/CreditAuth/Guarantor/GuarantorAcceptForm1";
import GuarantorAcceptForm2 from "components/CreditAuth/Guarantor/GuarantorAcceptForm2";
import { GuarantorCreditAcceptFormProps } from "types/views";
import { Button } from "../../../components/Button";
import {
	useGuarantorAccept,
	useGuarantorDeclineCredit,
	useRetailerGuarantorVerify,
} from "hooks/useCreditData";
import { RequestSuccessFailProps } from "types/components/RequestSuccessFail";
import { AxiosError, AxiosResponse } from "axios";
import { isValidAddress, isValidName } from "utils/validation";
import { Toast } from "../../../utils/toast";
import { base64ToBlob } from "../../../utils/utilities";

const validateGuarantorStep1 = (formData: GuarantorCreditAcceptFormProps) => {
	if (!isValidName(formData.first_name)) {
		Toast.error("Enter a valid first name");
		return false;
	} else if (!isValidName(formData.last_name)) {
		Toast.error("Enter a valid last name");
		return false;
	} else if (!isValidAddress(formData.address)) {
		Toast.error("Enter a valid address");
		return false;
	} else if (!formData.dob) {
		Toast.error("Enter a your date of birth");
		return false;
	} else if (!formData.relationship) {
		Toast.error("Type in a relationship other than family");
		return false;
	} else if (!formData.occupation_type) {
		Toast.error("Select an occupation type");
		return false;
	} else if (formData.occupation_type) {
		if (formData.occupation_type === "salary_earner") {
			if (!formData.work_address) {
				Toast.error("Type in your work address");
				return false;
			}
		} else if (formData.occupation_type === "self_employed") {
			if (!formData.business_address) {
				Toast.error("Type in your business address");
				return false;
			}
		}
	}
	return true;
};
const validateGuarantorStep2 = (
	formData: GuarantorCreditAcceptFormProps,
	acceptTerms: boolean,
	acceptGuarantorTerms: boolean
) => {
	if (!formData.bank_statement) {
		Toast.error("Kindly Upload your bank statement");
		return false;
	} else if (acceptTerms === false) {
		Toast.info("Accept our terms and condition");
		return false;
	} else if (acceptGuarantorTerms === false) {
		Toast.info("Kindly Accept to stand in as a guarantor");
		return false;
	}
	return true;
};

const RetailerGuarantorAccept = () => {
	const navigate = useNavigate();
	const initialData: GuarantorCreditAcceptFormProps = {
		first_name: "",
		last_name: "",
		dob: "",
		address: "",
		relationship: "",
		occupation_type: "",
		work_address: "",
		business_address: "",
		bank_statement: "",
		bank_statementName: "",
		signature_document: "",
		signature_documentName: "",
	};
	const [page, setPage] = useState<0 | 1>(0);
	const [formData, setFormData] =
		useState<GuarantorCreditAcceptFormProps>(initialData);
	const [requestFormData, setRequestFormData] =
		useState<RequestSuccessFailProps>({
			state: false,
			title: "",
			subtitle: "",
		});
	// const [isLoading, setIsLoading] = useState(false);
	const [authParams, setAuthParams] = useState(null);
	const [creditData, setCreditData] = useState(null);
	const [acceptTerms, setAcceptTerms] = useState(false);
	const [acceptGuarantorTerms, setAcceptGuarantorTerms] = useState(false);
	const [showRequestPage, setShowRequestPage] = useState(false);

	const verifyGuarantorOnError = (error: AxiosError) => {
		switch (error?.response?.status) {
			case 404:
				setRequestFormData({
					state: false,
					title: "This link could not be verified",
					subtitle: "The credit could not be verified",
				});
				break;

			default:
				setRequestFormData({
					state: false,
					title: "This link could not be verified",
					subtitle: "The credit could not be verified",
				});
				break;
		}

		setShowRequestPage(true);
	};
	const verifyGuarantorOnSuccess = (data: AxiosResponse) => {
		setCreditData(data?.data.data);
		if (creditData) {
			setFormData({
				...formData,
				first_name: creditData?.name.split(" ")[0],
				last_name: creditData?.name.split(" ")[1],
				address: creditData?.address,
				relationship: creditData?.relationship,
			});
		}
	};
	const declineCreditOnSuccess = () => {
		setRequestFormData({
			state: true,
			title: "Credit Declined",
			subtitle: "Credit has been successfully declined.",
		});
		setShowRequestPage(true);
	};

	const declineCreditOnError = () => {
		setRequestFormData({
			state: false,
			title: "This link has expired",
			subtitle: "The credit could not be declined.",
		});
		setShowRequestPage(true);
	};

	const guarantorAcceptOnSuccess = () => {
		setRequestFormData({
			state: true,
			title: "Successful!",
			subtitle:
				"Congratulations, your guarantor authorisation has been accepted!",
		});
		setShowRequestPage(true);
		localStorage.removeItem("signaturePhoto");
		localStorage.removeItem("bankStatement");
		localStorage.removeItem("bankStatementName");
		localStorage.removeItem("signaturePhotoName");
	};

	const guarantorAcceptOnError = () => {
		setRequestFormData({
			state: false,
			title: "This link has expired",
			subtitle: "The guarantor authorisation could not be accepted!",
		});
		setShowRequestPage(true);
	};

	const { isLoading: verifyLoading, refetch } = useRetailerGuarantorVerify(
		authParams,
		verifyGuarantorOnSuccess,
		verifyGuarantorOnError
	);

	const { mutateGuarantorDeclineCredit, isLoading: declineIsLoading } =
		useGuarantorDeclineCredit(declineCreditOnSuccess, declineCreditOnError);

	const { mutateGuarantorAccept, isLoading: acceptLoading } =
		useGuarantorAccept(
			guarantorAcceptOnSuccess,
			guarantorAcceptOnError,
			authParams
		);

	const handleGuarantorAccept = () => {
		if (
			!validateGuarantorStep2(formData, acceptTerms, acceptGuarantorTerms)
		) {
			return;
		}
		if (authParams) {
			const guarantorFormData: FormData = new FormData();
			guarantorFormData.append(
				"name",
				formData.first_name + " " + formData.last_name
			);
			guarantorFormData.append("address", formData.address);
			guarantorFormData.append("date_of_birth", formData.dob);
			guarantorFormData.append(
				"occupation_type",
				formData.occupation_type
			);
			if (formData.bank_statement) {
				guarantorFormData.append(
					"bank_statement",
					base64ToBlob(formData.bank_statement)
				);
			}
			if (formData.signature_document) {
				guarantorFormData.append(
					"signature",
					base64ToBlob(formData.signature_document)
				);
			}
			if (formData.occupation_type === "salary_earner") {
				guarantorFormData.append(
					"place_of_work",
					formData.work_address
				);
			} else {
				guarantorFormData.append(
					"place_of_work",
					formData.business_address
				);
			}
			mutateGuarantorAccept(guarantorFormData);
		}
	};

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		const codeParam = queryParams.get("auth");
		if (codeParam) {
			setAuthParams(codeParam);
		} else {
			// navigate("/");
		}
	}, [authParams, navigate]);

	useEffect(() => {
		if (authParams) {
			refetch();
		}
	}, [authParams, refetch]);

	return (
		<div className="mx-auto flex min-h-[100vh] w-full flex-col items-center pb-8 pt-5">
			<div className="mb-3 flex flex-col items-center justify-center p-3">
				<figure className="h-8 w-28">
					<img src={Logo} alt="hadi logo" />
				</figure>
				<header className="relative py-2">
					<h3 className="mx-auto px-5 text-center text-2xl font-semibold text-blue-950">
						Retailer Guarantor Accept
					</h3>
				</header>
			</div>
			{verifyLoading && (
				<div
					className={`fixed inset-0 flex scale-[2] items-center justify-center bg-white bg-opacity-60 `}
				>
					<Loader2 color="#061F6A" />
				</div>
			)}
			{!verifyLoading ? (
				!showRequestPage ? (
					<div className="flex w-full max-w-[640px] flex-col gap-[18px] px-5">
						<form
							encType="multipart/form-data"
							className="rounded-[4px] border bg-[#F9FCFF] bg-opacity-50 p-5 text-[14px] text-slate-500 shadow-sm backdrop-blur-lg backdrop-filter"
						>
							<h4 className="text-[24px] font-semibold text-blue-950">
								Guarantor
							</h4>
							<div className="bg-[#C7C7C7] bg-opacity-10 p-4">
								<p className="w-full text-[14px] text-slate-600">
									Please enter correct guarantor details
									before authorising the credit application
								</p>
							</div>
							{page === 0 ? (
								<GuarantorAcceptForm1
									disabled={false}
									formData={formData}
									setFormData={setFormData}
								/>
							) : null}
							{page === 1 ? (
								<GuarantorAcceptForm2
									disabled={false}
									formData={formData}
									setFormData={setFormData}
								/>
							) : null}

							<div>
								{page === 0 ? (
									<Button
										disabled={false}
										additionalClasses="text-white"
										onClick={() => {
											if (
												!validateGuarantorStep1(
													formData
												)
											) {
												return;
											} else {
												setPage(1);
											}
										}}
									>
										Next
									</Button>
								) : null}
								{page === 1 ? (
									<div className="space-y-4">
										<div
											className="my-2 flex gap-2 text-[14px] text-slate-600"
											onClick={() => {
												setAcceptTerms(!acceptTerms);
											}}
										>
											<input
												type="checkbox"
												id="checkboxTerms"
												className="h-4 w-4 flex-shrink-0 cursor-pointer border"
												checked={acceptTerms}
												onChange={() =>
													setAcceptTerms(!acceptTerms)
												}
											/>
											<label
												htmlFor="checkboxTerms"
												className="cursor-pointer text-sm leading-5"
											>
												I hereby confirm my acceptance
												of the{" "}
												<span className="text-blue-800">
													Terms and Conditions
												</span>{" "}
												outlined in the agreement. I am
												fully aware of the
												responsibilities and commitments
												involved, and I am eager to
												proceed as agreed. Kindly
												consider this as my official
												acceptance. Thank you!
											</label>
										</div>
										<div
											className="my-2 flex gap-2 text-[14px] text-slate-600"
											onClick={() => {
												setAcceptGuarantorTerms(
													!acceptGuarantorTerms
												);
											}}
										>
											<input
												type="checkbox"
												className="h-4 w-4 flex-shrink-0 cursor-pointer border"
												id="checkBoxGuarantorTerms"
												checked={acceptGuarantorTerms}
												onChange={() =>
													setAcceptGuarantorTerms(
														!acceptGuarantorTerms
													)
												}
											/>
											<label
												htmlFor="checkBoxGuarantorTerms"
												className="cursor-pointer text-sm leading-5"
											>
												Do you accept to be the
												Guarantor for this User
											</label>
										</div>
										<div className="flex justify-between pt-4">
											<Button
												disabled={
													declineIsLoading ||
													acceptLoading
												}
												onClick={() => {
													mutateGuarantorDeclineCredit(
														authParams
													);
												}}
												additionalClasses="rounded-[4px] bg-[#D52500] py-2 font-normal text-white max-w-[136px] text-sm"
											>
												{declineIsLoading ? (
													<Loader2 />
												) : (
													"Reject"
												)}
											</Button>
											<Button
												disabled={
													declineIsLoading ||
													acceptLoading
												}
												additionalClasses="rounded-[4px] border border-[#BABFC3] px-12 py-2 text-[14px] text-[#202223] max-w-[136px] bg-white"
												onClick={() =>
													handleGuarantorAccept()
												}
											>
												{acceptLoading ? (
													<Loader2 color="#061F6A" />
												) : (
													"Proceed"
												)}
											</Button>
										</div>
									</div>
								) : null}
							</div>
						</form>
					</div>
				) : (
					<RequestSuccessFail
						state={requestFormData.state}
						title={requestFormData.title}
						subtitle={requestFormData.subtitle}
					/>
				)
			) : null}
		</div>
	);
};

// {/* <div className="relative mt-[18px] rounded-[4px] border bg-[#F9FCFF] bg-opacity-50 p-5 text-[14px] text-slate-500 shadow-sm backdrop-blur-lg backdrop-filter">
// 							<div className="flex flex-col gap-2">
// 								<p>
// 									Credit Applicant:{" "}
// 									{creditData?.retailer_name}{" "}
// 								</p>
// 								{/* This can change to content_object.total_amount later  */}
// 								// <p>
// 								// 	Purchase Amount: ₦
// 								// 	{creditData?.amount
// 								// 		? Number(
// 								// 				creditData.amount.split(".")[0]
// 								// 		  ).toLocaleString()
// 								// 		: null}{" "}
// 								// </p>
// 								// <p>
// 								// 	Credit Amount: ₦
// 								// 	{creditData?.amount
// 								// 		? Number(
// 								// 				creditData.amount.split(".")[0]
// 								// 		  ).toLocaleString()
// 								// 		: null}{" "}
// 								// </p>
// 							// </div>
// 						// </div> */}

export default RetailerGuarantorAccept;
