import { useState, Fragment } from "react";
import { Button, Loader2, FormikInput } from "../components";
import blackLogo from "../assets/image/blackLogo.svg";
import { Toast } from "../utils/toast";
import { usePasswordReset } from "../hooks/useAuthData";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { PasswordResetData } from "@/types/hooks";
import { AxiosError } from "axios";
import { ErrorResponse } from "@/types/general";

const ForgotPassword = () => {
	const navigate = useNavigate();
	const [formData] = useState<{
		email: string;
	}>({
		email: "",
	});

	const onError = (error: AxiosError) => {
		Toast.error(
			(error?.response?.data as ErrorResponse)?.message ??
				"Something went wrong"
		);
	};

	const onSuccess = (): void => {
		Toast.success("Kindly check your email for a reset link");
		navigate("/login");
	};

	const { mutate: passwordReset, isLoading } = usePasswordReset(
		onSuccess,
		onError
	);

	const handleForgotPassword = (payLoad: PasswordResetData) => {
		passwordReset(payLoad);
	};

	return (
		<Fragment>
			<div className="item-center flex w-full justify-between mxs:flex-col mxs:items-center">
				<figure className="block h-8 w-28 lg:hidden">
					<img src={blackLogo} alt="Hadi logo" />
				</figure>
				<Link
					to="/register"
					className="flex w-full cursor-pointer items-center justify-end text-right text-xs font-[500] text-[#334155] sm:text-[16px] mxs:justify-center mxs:text-center"
				>
					Don&apos;t have an account?{" "}
					<span className="ml-1 cursor-pointer text-[#002EA9]">
						Sign up
					</span>
				</Link>
			</div>
			<div className="relative mx-auto flex w-full flex-1 items-center justify-center sm:mt-16 xl:mt-0">
				<div className="flex h-full w-full flex-col items-center justify-center px-3">
					<div className="flex min-h-[450px] w-full max-w-[500px] flex-col gap-y-4">
						<h3 className="w-full text-left text-lg font-[600] leading-[1] text-primary-900 sm:text-[32px] lg:leading-[56px]">
							Forgot Password?
						</h3>
						<div className="pb-4 text-xs  md:text-sm lg:text-base">
							<p className=" font-[400] text-secondary-400">
								Don&apos;t worry this usually happens.
							</p>
							<p className=" font-[400] text-secondary-400">
								Please enter the email address linked to your
								account
							</p>
						</div>

						<Formik
							initialValues={formData}
							validationSchema={Yup.object({
								email: Yup.string()
									.required("Email is required")
									.email("Invalid email address"),
							})}
							onSubmit={handleForgotPassword}
						>
							{() => (
								<Form className="flex w-full flex-col gap-y-6 md:gap-y-12">
									<FormikInput
										label="Email Address"
										floatingLabel={true}
										name="email"
										id="email"
										type="email"
										placeholder="Enter your email address"
										additionalClasses="border-[#263238] placeholder-[#263238] h-10 md:h-14"
										disabled={isLoading}
										required={true}
										autoComplete="off"
									/>
									<Button
										disabled={isLoading}
										additionalClasses={
											"text-lg text-white font-medium bg-primary-800  text-sm md:text-base"
										}
										type="submit"
									>
										{isLoading ? (
											<Loader2 />
										) : (
											"Send reset link"
										)}
									</Button>
								</Form>
							)}
						</Formik>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default ForgotPassword;
