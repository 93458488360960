import { AxiosError, AxiosResponse } from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { logDev } from "../utils/utilities";
import {
	LogoutUserData,
	changePasswordData,
	loginUserData,
} from "../types/hooks";
import { Toast } from "../utils/toast";
import { deleteAllCookies, deleteCookie, setCookie } from "../utils/cookies";
import {
	loginUser,
	logoutUser,
	registerDistributor,
	passwordReset,
	passwordResetConfirm,
	passwordResetVerifyToken,
	changePassword,
	linkQuickbooks,
	accountConfirm,
	resendEmail,
	linkZohoCRM,
	revokeZohoCRMAccess,
	linkMono,
	onboardRetailer,
	retailerSearch,
	retailerGurantorSearch,
	fieldAgent,
} from "../api/auth";
import { useAuth } from "./auth";
import { forceLogout, setAccessToken } from "../utils/axios-utils";
import { on } from "events";
import { useNotificationWebSocketContext } from "../provider/NotificationWebSocketProvider";
import { MonoConnectResponse } from "@/components/MonoConnectComponent";
import mixpanel from "mixpanel-browser";

export const useLoginUser = (
	onError: (
		error: AxiosError<{ message: string }>,
		payload: loginUserData
	) => void
) => {
	const navigate = useNavigate();
	const { setUser } = useAuth();
	const location = useLocation();
	const notificationContext = useNotificationWebSocketContext();

	return useMutation(loginUser, {
		onSuccess: (data) => {
			const access = data.data.access;
			setCookie("ac-token", access, 0.0208333);
			setAccessToken(access);
			const UserData = data.data.user;
			localStorage.setItem("hadi_user", JSON.stringify(UserData));
			localStorage.setItem(
				"unread_notifications_count",
				JSON.stringify(UserData.unread_notifications_count)
			);
			if (notificationContext)
				notificationContext.setUnreadNotificationCount(
					UserData.unread_notifications_count
				);
			setUser(UserData);
			const to = location.state.from?.pathname;
			navigate(to, { replace: true });
		},
		onError: (
			error: AxiosError<{ message: string }>,
			payload: loginUserData
		) => {
			onError(error, payload);
		},
	});
};

export const useLogoutUser = () => {
	const navigate = useNavigate();
	const { setUser } = useAuth();
	const queryClient = useQueryClient();
	return useMutation<AxiosResponse<LogoutUserData>, unknown, void>(
		logoutUser,
		{
			onSuccess: () => {
				localStorage.clear();
				deleteAllCookies();
				deleteCookie("ac-token");
				setUser(null);
				Toast.success("Logout successful");
				queryClient.clear();
				navigate("/");
				mixpanel.track("Logged Out");
			},
			onError: () => {
				//Force logout on logout error
				forceLogout();
			},
		}
	);
};

export const useLinkToZohoCRM = (
	onSuccess: (response: AxiosResponse) => void,
	onError: (response: AxiosResponse) => void
) => {
	return useQuery("link_zohocrm", linkZohoCRM, {
		enabled: false,
		onSuccess: (response: AxiosResponse) => {
			onSuccess(response);
		},
		onError: (response: AxiosResponse) => {
			onError(response);
		},
	});
};
export const useLinkToQuickbooksCRM = (
	onSuccess: (response: AxiosResponse) => void,
	onError: (response: AxiosResponse) => void
) => {
	return useQuery("link_quickbooks", linkQuickbooks, {
		enabled: false,
		onSuccess: (response: AxiosResponse) => {
			onSuccess(response);
		},
		onError: (response: AxiosResponse) => {
			onError(response);
		},
	});
};

export const useDisconnectZohoCRM = (
	onSuccess: (response: AxiosResponse) => void,
	onError: (error: AxiosError) => void
) => {
	return useQuery("disconnect_zohocrm", revokeZohoCRMAccess, {
		enabled: false,
		onSuccess: (response: AxiosResponse) => {
			onSuccess(response);
		},
		onError: (error: AxiosError) => {
			onError(error);
		},
	});
};

export const useRegisterDistributor = (
	onSuccess: () => void,
	onError: (error: AxiosResponse) => void
) => {
	return useMutation(registerDistributor, {
		onSuccess: () => {
			onSuccess();
		},
		onError: (error: AxiosResponse) => {
			onError(error);
		},
	});
};

export const useAccountConfirm = (
	onSuccess: () => void,
	onError: () => void
) => {
	return useMutation(accountConfirm, {
		onSuccess: () => {
			onSuccess();
		},
		onError: () => {
			onError();
		},
	});
};

export const usePasswordReset = (
	onSuccess: () => void,
	onError: (error: AxiosError) => void
) => {
	return useMutation(passwordReset, {
		onSuccess: (data: AxiosResponse) => {
			onSuccess();
			logDev(data);
		},
		onError: (error: AxiosError) => {
			onError(error);
		},
	});
};

export const usePasswordResetConfirm = (
	onError: (data: AxiosError) => void,
	onSuccess: (data: AxiosResponse) => void
) => {
	const navigate = useNavigate();
	return useMutation(passwordResetConfirm, {
		onSuccess: (data: AxiosResponse) => {
			onSuccess(data);
			Toast.success(data.data.message);
			setTimeout(() => {
				navigate("/login");
			}, 2000);
		},
		onError: (error: AxiosError) => {
			onError(error);
		},
	});
};

export const usePasswordResetVerifyToken = (
	onSuccess: (data: AxiosResponse) => void,
	onError: (data: AxiosResponse) => void
) => {
	return useMutation(passwordResetVerifyToken, {
		onSuccess: (data: AxiosResponse) => {
			onSuccess(data);
			Toast.success(data.data.message);
			logDev(data);
		},
		onError: (error: AxiosResponse) => {
			onError(error);
			logDev(error);
			// if (error.response.status >= 400) navigate("/login");
		},
	});
};

export const useChangePassword = (
	onSuccess: (data: AxiosResponse) => void,
	onError: (error: AxiosResponse) => void
) => {
	const queryClient = useQueryClient();
	const navigate = useNavigate();
	const { mutate, ...rest } = useMutation<
		AxiosResponse,
		AxiosResponse,
		changePasswordData
	>(changePassword, {
		onSuccess: (data: AxiosResponse) => {
			queryClient.invalidateQueries("customer");
			logDev(data);
			onSuccess(data);
			// setTimeout(() => {
			// 	navigate("/dashboard");
			// }, 2500);
		},
		onError: (error: AxiosResponse) => {
			onError(error);
			logDev(error);
		},
	});

	return { changePassword: mutate, ...rest };
};

export const useResendEmail = (onSuccess: () => void, onError: () => void) => {
	return useMutation(resendEmail, {
		onSuccess(data: AxiosResponse) {
			onSuccess();
			logDev(data);
		},
		onError: (error: AxiosResponse) => {
			onError();
			logDev(error);
		},
	});
};

// export const useLinkToMono = (
// 	onSuccess: (data: AxiosResponse) => void = () => {
// 		return;
// 	}
// ) => {
// 	const mutation = useMutation((formData) => linkMono(formData), {
// 		onError: (error: AxiosError) => {
// 			switch (error?.response?.status) {
// 				case 403:
// 					Toast.error("Authentication could not be completed");
// 					break;
// 				case 500:
// 					Toast.error("Authentication could not be completed");
// 					break;
// 				default:
// 					Toast.error("An error occured 🤕, kindly try again");
// 					break;
// 			}
// 		},

// 		onMutate: (formData: MonoConnectResponse) => {
// 			return formData;
// 		},
// 		onSuccess: (data: AxiosResponse) => {
// 			onSuccess(data);
// 		},
// 	});

// 	return { mutateConfirmMonoAuth: mutation.mutate, ...mutation };
// };

export const useLinkToMono = (onSuccess: () => void) => {
	const { mutate, ...rest } = useMutation(linkMono, {
		onSuccess: () => {
			onSuccess();
		},
		onError: (error: AxiosError) => {
			switch (error?.response?.status) {
				case 403:
					Toast.error("Authentication could not be completed");
					break;
				case 500:
					Toast.error("Authentication could not be completed");
					break;
				default:
					Toast.error("An error occured 🤕, kindly try again");
					break;
			}
		},
	});
	return { mutateConfirmMonoAuth: mutate, ...rest };
};

export const useRetailerOnboarding = (
	onSuccess: (data: AxiosResponse) => void,
	onError: (data: AxiosError) => void
) => {
	const { mutate, ...rest } = useMutation(onboardRetailer, {
		onSuccess,
		onError,
	});
	return { mutateRetailerOnboarding: mutate, ...rest };
};
export const useRetailerSearch = (
	onSuccess: (data: AxiosResponse) => void,
	onError: (data: AxiosError) => void
) => {
	const { mutate, ...rest } = useMutation(retailerSearch, {
		onSuccess,
		onError,
	});
	return { mutateRetailerSearch: mutate, ...rest };
};
export const useRetailerGurantorSearch = (
	onSuccess: (data: AxiosResponse) => void,
	onError: (data: AxiosError) => void
) => {
	const { mutate, ...rest } = useMutation(retailerGurantorSearch, {
		onSuccess,
		onError,
	});
	return { mutateRetailerGurantorSearch: mutate, ...rest };
};

export const useFieldAgent = (
	onSuccess: (data: AxiosResponse) => void,
	onError: (data: AxiosError) => void
) => {
	const { mutate, ...rest } = useMutation(fieldAgent, {
		onSuccess,
		onError,
	});
	return { mutatefieldAgent: mutate, ...rest };
};
