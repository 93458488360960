import React, { useEffect } from "react";
import "./App.css";
import AppRoutes from "./Routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthProvider } from "./provider/AuthProvider";
import { SidebarViewProvider } from "./provider/SidebarViewProvider";
import { setLogger } from "react-query";
import NotificationWebSocketProvider from "./provider/NotificationWebSocketProvider";
import { OnboardingProvider } from "./provider/OnboardingProvider";
import { ThemeProvider } from "./components/theme-provider";
import NotificationProvider from "./provider/NotificationProvider";
import { initGA, logPageView } from "./analytics";
import mixpanel from "mixpanel-browser";
import { MIXPANEL_TOKEN } from "./services/Constants";

function App() {
	// Stopping console log errors from react-query
	setLogger({
		log: () => {
			// Log debugging information
		},
		warn: () => {
			// Log warning
		},
		error: () => {
			// Log error
		},
	});

	mixpanel.init(MIXPANEL_TOKEN!, {
		debug: process.env.NODE_ENV !== "production",
		track_pageview: true,
		persistence: "localStorage",
	});

	useEffect(() => {
		initGA();
		logPageView();
	}, []);

	return (
		<ThemeProvider defaultTheme="dark" storageKey="vite-ui-theme">
			<AuthProvider>
				<OnboardingProvider>
					<NotificationProvider>
						{/* <NotificationWebSocketProvider> */}
						<SidebarViewProvider>
							<div>
								<ToastContainer
									autoClose={3000}
									newestOnTop={false}
									closeOnClick
								/>
								<AppRoutes />
							</div>
						</SidebarViewProvider>
						{/* </NotificationWebSocketProvider> */}
					</NotificationProvider>
				</OnboardingProvider>
			</AuthProvider>
		</ThemeProvider>
	);
}

export default App;
