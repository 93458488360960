import React from "react";
import { Button, Loader2 } from "../../../../components";
// import { Gender } from "../../services/Constants";

interface FormErrorProps {
	verifyLoading: boolean;
}

const GuarantorFormError: React.FC<FormErrorProps> = ({
	verifyLoading = true,
}) => {
	const closeWindow = () => {
		window.location.href = "https://hadifinance.com";
	};
	return (
		<>
			{verifyLoading && (
				<div
					className={`fixed inset-0 flex scale-[2] items-center justify-center bg-white bg-opacity-60 `}
				>
					<Loader2 color="#061F6A" />
				</div>
			)}
			{!verifyLoading ? (
				<>
					<div className="flex w-full max-w-[640px] flex-col items-end justify-center">
						<Button
							disabled={false}
							onClick={closeWindow}
							additionalClasses="text-red-500 underline bg-transparent w-auto leading"
						>
							Close
						</Button>
					</div>
					<div className="mt-14 flex h-full w-full max-w-[640px] justify-between space-x-6">
						<div className="w-full">
							<div className="mt-[15px] w-full">
								<h3 className="text-center text-2xl font-medium leading-7 text-[#1A4393]">
									This link has expired
								</h3>
								<p className="mt-2 text-center text-[#90A3BF]">
									The guarantor authorisation could not be
									accepted!
								</p>
							</div>
							<div className="flex items-center justify-center py-8">
								<svg
									width="120"
									height="119"
									viewBox="0 0 120 119"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M44.5156 80L52.5651 71.8952L64.9609 59.4993L77.3568 47.1035"
										stroke="#BA1A1A"
										strokeWidth="5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M44.4863 47.1309L52.5911 55.1803L64.987 67.5762L77.3828 79.972"
										stroke="#BA1A1A"
										strokeWidth="5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M35.2096 16.5503C42.7439 12.1915 51.297 9.90266 60.0013 9.91607C87.3862 9.91607 109.585 32.1145 109.585 59.4994C109.585 86.8843 87.3862 109.083 60.0013 109.083C32.6164 109.083 10.418 86.8843 10.418 59.4994C10.418 50.4703 12.8327 41.9965 17.0522 34.7077"
										stroke="#BA1A1A"
										strokeWidth="5"
										strokeLinecap="round"
									/>
								</svg>
							</div>

							<Button
								disabled={false}
								type="button"
								onClick={closeWindow}
								additionalClasses="text-lg text-white font-medium bg-primary-800 mt-[14px] lg:mt-[27px] col-span-2"
							>
								Close Page
							</Button>
						</div>
					</div>
				</>
			) : null}
		</>
	);
};

export default GuarantorFormError;
