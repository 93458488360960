import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import Logo from "../../../assets/image/blackLogo.svg";
import { GuarantorCreditAcceptFormV2Props } from "types/views";
import {
	useGuarantorAcceptV2,
	useGuarantorDeclineCredit,
	useRetailerGuarantorVerifyV2,
} from "hooks/useCreditData";
import { RequestSuccessFailProps } from "types/components/RequestSuccessFail";
import { AxiosError, AxiosResponse } from "axios";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import GuarantorFormStep1 from "./GuarantorFormStep1";
import GuarantorFormStep2 from "./GuarantorFormStep2";
import GuarantorFormStep3 from "./GuarantorFormStep3";
import GuarantorFormSuccess from "./GuarantorFormSuccess";
import GuarantorFormError from "./GuarantorFormError";
import { toast } from "react-toastify";
import GuarantorLoading from "./GurantorLoading";

const NewRetailerGuarantorForm = () => {
	const [activeStep, setActiveStep] = useState<number>(0);
	const navigate = useNavigate();
	const [requestFormData, setRequestFormData] =
		useState<RequestSuccessFailProps>({
			state: false,
			title: "",
			subtitle: "",
		});
	const [authParams, setAuthParams] = useState(null);
	const [creditData, setCreditData] = useState(null);
	// const [showRequestPage, setShowRequestPage] = useState(false);

	const [formData, setFormData] = useState<GuarantorCreditAcceptFormV2Props>({
		name: "",
		borrower_name: "",
		borrower_address: "",
		address: "",
		nin: "",
		state: "",
		lga: "",
		bvn: "",
		contact_phone: "",
		date_of_birth: "",
		relationship: "",
		occupation_type: "",
		work_address: "",
		business_address: "",
		bank_statement: "",
		bank_statementName: "",
		signature_document: "",
		signature_documentName: "",
		utility_document: "",
		utility_documentName: "",
		date_of_signature: "",
		terms: false,
	});

	const validationSchema = [
		Yup.object({}),

		Yup.object({
			contact_phone: Yup.string()
				.length(11, "Enter a valid number")
				.required("Phone number is required"),
			bvn: Yup.string()
				.length(11, "BVN must be exactly 11 characters")
				.required("BVN is required"),
			nin: Yup.string()
				.max(11, "NIN must not exceed 11 characters")
				.required("NIN is required"),
			state: Yup.string().required("State is required"),
			lga: Yup.string().required("LGA is required"),
			utility_document: Yup.string().required("Utility bill is required"),
		}),

		Yup.object({
			bvn: Yup.string()
				.length(11, "BVN must be exactly 11 characters")
				.required("BVN is required"),
			nin: Yup.string()
				.max(17, "NIN must not exceed 17 characters")
				.required("NIN is required"),
			relationship: Yup.string().required("Relationship is required"),
			occupation_type: Yup.string().required("Occupation is required"),
			date_of_birth: Yup.string().required("Date of birth is required"),
			state: Yup.string().required("State is required"),
			lga: Yup.string().required("LGA is required"),
			utility_document: Yup.string().required("Utility bill is required"),
			name: Yup.string().required("Name is required"),
			address: Yup.string().required("Address is required"),
			signature_document: Yup.string().required("Signature is required"),
			terms: Yup.boolean().oneOf(
				[true],
				"Accept the terms and conditions"
			),
		}),
	];

	const currentValidationSchema = validationSchema[activeStep];

	const verifyGuarantorOnError = (error: AxiosError) => {
		switch (error?.response?.status) {
			case 404:
				setRequestFormData({
					state: false,
					title: "This link could not be verified",
					subtitle: "The credit could not be verified",
				});
				break;

			default:
				setRequestFormData({
					state: false,
					title: "This link could not be verified",
					subtitle: "The credit could not be verified",
				});
				break;
		}

		// setShowRequestPage(true);
	};
	const verifyGuarantorOnSuccess = (data: AxiosResponse) => {
		setCreditData(data?.data.data);
		if (creditData) {
			// setFormData({
			// 	...formData,
			// 	first_name: creditData?.name.split(" ")[0],
			// 	last_name: creditData?.name.split(" ")[1],
			// 	address: creditData?.address,
			// 	relationship: creditData?.relationship,
			// });
		}
	};
	const declineCreditOnSuccess = () => {
		setRequestFormData({
			state: true,
			title: "Credit Declined",
			subtitle: "Credit has been successfully declined.",
		});
		// setShowRequestPage(true);
	};

	const declineCreditOnError = () => {
		setRequestFormData({
			state: false,
			title: "This link has expired",
			subtitle: "The credit could not be declined.",
		});
	};

	const guarantorAcceptOnSuccess = () => {
		setRequestFormData({
			state: true,
			title: "Successful!",
			subtitle:
				"Congratulations, your guarantor authorisation has been accepted!",
		});
		setActiveStep(activeStep + 1);
		localStorage.removeItem("signaturePhoto");
		localStorage.removeItem("bankStatement");
		localStorage.removeItem("bankStatementName");
		localStorage.removeItem("signaturePhotoName");
	};

	const guarantorAcceptOnError = (error: AxiosError) => {
		if (error.response && error.response.data) {
			const data = error.response.data as { message?: string };
			setRequestFormData({
				state: false,
				title: "This link has expired",
				subtitle: "The guarantor authorisation could not be accepted!",
			});
			toast.error(data.message || "Something went wrong");
		}
	};

	const {
		data,
		isLoading: verifyLoading,
		refetch,
	} = useRetailerGuarantorVerifyV2(
		authParams,
		verifyGuarantorOnSuccess,
		verifyGuarantorOnError
	);

	const { mutateGuarantorDeclineCredit, isLoading: declineIsLoading } =
		useGuarantorDeclineCredit(declineCreditOnSuccess, declineCreditOnError);

	const { mutateGuarantorAccept, isLoading: acceptLoading } =
		useGuarantorAcceptV2(
			guarantorAcceptOnSuccess,
			guarantorAcceptOnError,
			authParams
		);

	const handleDeclineCredit = () => {
		console.log("decline");
		if (authParams) {
			mutateGuarantorDeclineCredit(authParams);
		}
	};

	const handleSubmit = async (
		payload: GuarantorCreditAcceptFormV2Props,
		actions: any
	) => {
		if (activeStep < 2) {
			setActiveStep(activeStep + 1);
			actions.setTouched({});
			actions.setSubmitting(false);
			return;
		} else {
			if (authParams) {
				const guarantorFormData: FormData = new FormData();
				guarantorFormData.append("name", payload.name);
				guarantorFormData.append("address", payload.address);
				guarantorFormData.append(
					"contact_phone",
					payload.contact_phone
				);

				guarantorFormData.append("nin", payload.nin);
				guarantorFormData.append("lga", payload.lga);
				guarantorFormData.append("bvn", payload.bvn);
				guarantorFormData.append(
					"occupation_type",
					payload.occupation_type
				);
				guarantorFormData.append(
					"date_of_birth",
					payload.date_of_birth
				);
				guarantorFormData.append("relationship", payload.relationship);
				if (payload.signature_document) {
					const response = await fetch(payload.signature_document);
					const blob = await response.blob();
					const file = new File(
						[blob],
						payload.signature_documentName
					);
					guarantorFormData.append("signature", file);
				}
				if (payload.utility_document) {
					const response = await fetch(payload.utility_document);
					const blob = await response.blob();
					const file = new File([blob], payload.utility_documentName);
					guarantorFormData.append("utility", file);
				}
				mutateGuarantorAccept(guarantorFormData);
			}
		}
	};

	const RenderStepContent = (activeStep: number) => {
		switch (activeStep) {
			case 0:
				return (
					<GuarantorFormStep1
						verifyLoading={verifyLoading}
						declineIsLoading={declineIsLoading}
						acceptLoading={acceptLoading}
						handleDeclineCredit={handleDeclineCredit}
						retailerData={data?.data?.data}
					/>
				);
			case 1:
				return <GuarantorFormStep2 setActiveStep={setActiveStep} />;
			case 2:
				return (
					<GuarantorFormStep3
						acceptLoading={acceptLoading}
						setActiveStep={setActiveStep}
					/>
				);
			case 3:
				return <GuarantorFormSuccess />;
			case 4:
				return <GuarantorFormError verifyLoading={verifyLoading} />;
			case 5:
				return <GuarantorLoading verifyLoading={verifyLoading} />;
			default:
				return;
		}
	};

	useEffect(() => {
		// Extract nested data once to avoid repetition
		const formData = data?.data?.data;

		// Handle loading state first
		if (verifyLoading) {
			setActiveStep(5);
			return;
		}

		// Set default step for cases with no data or completed form
		if (!data || formData?.has_filled_form) {
			setActiveStep(4);
			return;
		}

		// Handle initial form data setup
		setFormData((prevData) => ({
			...prevData,
			borrower_name: formData?.name,
			borrower_address: formData?.residential_address,
			date_of_signature: new Date().toLocaleDateString("en-GB"),
		}));
		setActiveStep(0);
	}, [data, verifyLoading]);

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		const codeParam = queryParams.get("retailer");
		if (codeParam) {
			setAuthParams(codeParam);
		} else {
			// navigate("/");
		}
	}, [authParams, navigate]);

	useEffect(() => {
		if (authParams) {
			refetch();
		}
	}, [authParams, refetch]);

	return (
		<Formik
			initialValues={formData}
			enableReinitialize={true}
			validationSchema={currentValidationSchema}
			onSubmit={handleSubmit}
		>
			{() => {
				return (
					<Form className="mx-auto mt-2 flex h-screen min-h-[100vh] w-full flex-col items-center overflow-hidden p-4">
						{RenderStepContent(activeStep)}
					</Form>
				);
			}}
		</Formik>
	);
};

export default NewRetailerGuarantorForm;
