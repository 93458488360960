import React, { useEffect, useState } from "react";
import { RiWalletFill } from "react-icons/ri";
import {
	BsEyeSlashFill,
	BsEyeFill,
	BsFillVinylFill,
	BsChevronExpand,
} from "react-icons/bs";
import { Button, TopBar } from "../../../components";
import {
	formatDate,
	letter_profile_icon,
	getStatusColor,
	// starred_account_number,
} from "../../../utils/utilities";
import { GetTrendIcon } from "../../../utils/GetTrendIcon";
import {
	useGetAccountBeneficiaries,
	useGetAccountInfo,
	useGetPoolerWalletInfo,
	useGetAccountTransactionHistory,
	useGetAccountTransactions,
	useGetAllBanks,
	useCreatePaymentCode,
} from "../../../hooks/useWalletData";
import BankTransfer, {
	BeneficiaryData,
} from "../../../components/Dashboard/HadiPay/BankTransfer";
import RequestIsLoading from "../../../components/RequestIsLoading";
import { useAuth } from "../../../hooks/auth";
import SetNewPIN from "../../../components/Dashboard/HadiPay/SetNewPIN";
import ChangeTransactionPIN from "../../../components/Dashboard/ChangePin";
import AllSavedRecipients from "../../../components/Dashboard/HadiPay/AllSavedRecipients";
import NumberFormat from "../../../utils/NumberFormat";
import { FaSortDown, FaSortUp } from "react-icons/fa";
import { Link } from "react-router-dom";
import TransactionDetails from "../../../components/Dashboard/HadiPay/TransactionDetails";
import Select from "react-select";
import { AxiosResponse } from "axios";
import { Toast } from "../../../utils/toast";

interface SortOrder {
	column: string | null;
	order: "asc" | "desc";
}

interface TransactionDataInterface {
	date: string;
	amount: number;
	transaction_id: string;
	status: any;
	recipient_name: string | null;
	recipient_bank: string | null;
	retailer_name: string | null;
	transaction_type: string;
	narration: string;
}

const HadiPay: React.FC = () => {
	const { user } = useAuth();
	const onboardedUser = user?.distributor && user?.distributor?.is_onboarded;
	const isVerifiedUser =
		user?.distributor &&
		user?.distributor?.distributor_onboarding?.is_verified;
	user?.distributor?.user_profile?.is_verified;
	const [showPaymentModal, setShowPaymentModal] = useState(false);
	const [showAllSavedRecipients, setShowAllSavedRecipients] = useState(false);
	const [changePinToken, setChangePinToken] = useState("");
	const [showPIN, setShowPIN] = useState(false);
	const [showChangePIN, setShowChangePIN] = useState(false);
	const [toggleAccNum, setToggleAccNum] = useState(false);
	const [toggleBankName, setToggleBankName] = useState(false);
	const [transactionAttempt, setTransactionAttempt] = useState(0);
	const [changePinStage, setChangePinStage] = useState(0);

	const [showTransactionDetails, setShowTransactionDetails] = useState(false);
	const [transactionData, setTransactionData] = useState<any>({});

	const createPaymentCodeSuccess = (data: AxiosResponse) => {
		Toast.success(
			data?.data?.message ?? "Payment code created successfully"
		);
	};

	const createPaymentCode = useCreatePaymentCode(
		createPaymentCodeSuccess,
		(error) => {
			Toast.error(error.message ?? "An error occurred");
		}
	);

	const onError = () => {
		//
	};

	const getAccountOnError = () => {
		//
	};

	const getBeneficiariesOnSuccess = () => {
		//
	};

	const getBeneficiariesOnError = () => {
		//
	};
	const getBeneficiariesQuery = useGetAccountBeneficiaries(
		getBeneficiariesOnSuccess,
		getBeneficiariesOnError
	);

	const getAllBanksQuery = useGetAllBanks(undefined, onError);
	const getAccountInfoQuery = useGetAccountInfo(undefined, getAccountOnError);
	const getPoolerWalletQuery = useGetPoolerWalletInfo(
		undefined,
		getAccountOnError,
		Boolean(onboardedUser && isVerifiedUser)
	);

	const getPaymentCode =
		getPoolerWalletQuery.isFetched &&
		getPoolerWalletQuery.data?.data?.payment_code !== undefined
			? getPoolerWalletQuery.data?.data?.payment_code
			: "N/A";

	const getTransactionHistory = useGetAccountTransactions(undefined, onError);

	const [tableData, setTableData] = useState([]);

	const [sortOrder, setSortOrder] = useState<SortOrder>({
		column: null,
		order: "asc",
	});

	const getSortIcon = (column: string) => {
		if (sortOrder.column === column) {
			return sortOrder.order === "asc" ? (
				<FaSortUp />
			) : sortOrder.order === "desc" ? (
				<FaSortDown />
			) : (
				<BsChevronExpand />
			);
		}
		return <BsChevronExpand />;
	};

	const sortData = (column: string) => {
		const order =
			sortOrder.column === column && sortOrder.order === "asc"
				? "desc"
				: "asc";

		const sortedData =
			getTransactionHistory?.data.data &&
			Array.isArray(getTransactionHistory.data.data.transaction_history)
				? [...getTransactionHistory.data.data.transaction_history].sort(
						(a, b) => {
							if (order === "asc") {
								return a[column].localeCompare(b[column]);
							} else {
								return b[column].localeCompare(a[column]);
							}
						}
				  )
				: [];

		setTableData(sortedData);
		setSortOrder({ column, order });
	};

	const sortStatusData = (column: string, status: any) => {
		const order = "asc";

		const sortedData =
			getTransactionHistory?.data.data &&
			Array.isArray(getTransactionHistory.data.data.transaction_history)
				? [
						...getTransactionHistory.data.data.transaction_history,
				  ].filter((data) => data.transaction_type === status)
				: [];

		setTableData(sortedData);
		setSortOrder({ column, order });
	};
	const handleExportData = async () => {
		const data =
			getTransactionHistory?.data?.data?.transaction_history ?? [];
		const headers = Object.keys(data[0]);
		const csvContent =
			headers.join(",") +
			"\n" +
			data
				.map((row: any) =>
					headers.map((header) => row[header]).join(",")
				)
				.join("\n");

		const blob = new Blob([csvContent], { type: "text/csv" });

		const url = window.URL.createObjectURL(blob);
		const link = document.createElement("a");
		link.href = url;
		link.setAttribute("download", "transaction_history.csv");

		document.body.appendChild(link);
		link.click();

		document.body.removeChild(link);
		window.URL.revokeObjectURL(url);
	};

	const handleRowClick = (data: any) => {
		setShowTransactionDetails(true);
		setTransactionData(data);
	};

	useEffect(() => {
		if (!user.transfer_pin) setShowPIN(true);
	}, [user]);

	useEffect(() => {
		setTableData(
			getTransactionHistory?.data?.data?.transaction_history ?? []
		);
	}, [getTransactionHistory?.data?.data?.transaction_history]);

	return (
		<>
			<section className="h-full bg-[#F8FAFC]">
				<TopBar pageName="Hadi Pay" />
				<main className="px-4 pb-5 pt-4 sm:px-12 sm:pb-10 sm:pt-7">
					<section className="flex gap-8 overflow-x-auto mmd:flex-col">
						<section className="flex flex-1 flex-col gap-y-4">
							{/* Insight Row 1 */}
							<article className="flex flex-1 flex-col divide-x divide-[#F0F0F0] rounded-[10px] bg-white py-8 drop-shadow-sm md:flex-row mmd:flex-1">
								<HadiPayInsight
									title={"Account Balance"}
									amount={
										getPoolerWalletQuery?.data?.data
											?.balance
									}
									isLoading={getPoolerWalletQuery.isLoading}
									isFetched={getPoolerWalletQuery.isFetched}
								/>
								<HadiPayInsight
									title="Expected Inflow"
									amount={
										getAccountInfoQuery.data?.data?.data
											?.total_credits_incoming
									}
									isLoading={getAccountInfoQuery.isLoading}
									isFetched={getAccountInfoQuery.isFetched}
								/>
							</article>
							<article className="flex flex-1 flex-col divide-x divide-[#F0F0F0] rounded-[10px] bg-white py-8 drop-shadow-sm md:flex-row mmd:flex-1">
								<HadiPayInsight
									title={"Total Inflow"}
									amount={
										getTransactionHistory?.data?.data
											?.total_inflow
									}
									iconImage={GetTrendIcon(
										getTransactionHistory?.data?.data
											?.inflow_percentage,
										"icon"
									)}
									footNote={GetTrendIcon(
										getTransactionHistory?.data?.data
											?.inflow_percentage,
										"text"
									)}
									isLoading={getPoolerWalletQuery.isLoading}
									isFetched={getPoolerWalletQuery.isFetched}
								/>
								<HadiPayInsight
									title="Total Outflow"
									amount={
										getTransactionHistory?.data?.data
											?.total_outflow
									}
									iconImage={GetTrendIcon(
										getTransactionHistory?.data?.data
											?.outflow_percentage,
										"icon"
									)}
									footNote={GetTrendIcon(
										getTransactionHistory?.data?.data
											?.outflow_percentage,
										"text"
									)}
									isLoading={getAccountInfoQuery.isLoading}
									isFetched={getAccountInfoQuery.isFetched}
								/>
							</article>

							{/* Insight Row 2 */}
							{/* <article className="flex flex-1 flex-col gap-5 md:flex-row">
								<HadiPayInsight
									title="Total Outgoing"
									amount={
										getAccountInfoQuery.data?.data?.data
											?.total_outgoing_amount
									}
									isLoading={getAccountInfoQuery.isLoading}
									isFetched={getAccountInfoQuery.isFetched}
								/>
								<SavedRecipients />
							</article> */}
						</section>
						<aside className="relative flex flex-1 flex-col items-center rounded-[10px] bg-white  px-4 py-4 drop-shadow-sm md:max-w-[368px]">
							<div className="mb-[13px] rounded-[7px] bg-[#DDE1F966] px-[12px] py-[10px]">
								<span className="text-[10px]">
									NGN80.00 will be charged for inward
									transfers from external banks
								</span>
							</div>
							<div className="relative flex w-full flex-col items-center space-y-8 px-4 mmd:justify-between">
								<div className="flex flex-col items-center space-y-2 mmd:flex-1">
									<figure className="flex h-[60px] w-[60px] items-center justify-center rounded-full bg-[#002060] text-2xl font-semibold text-white">
										{getPoolerWalletQuery.isFetched &&
										getPoolerWalletQuery.data?.data
											?.display_name !== undefined
											? letter_profile_icon(
													getPoolerWalletQuery.data
														?.data?.display_name
											  )
											: "NA"}
									</figure>
									<p className="text-center font-medium text-[#002060]">
										{getPoolerWalletQuery.isFetched &&
										getPoolerWalletQuery.data?.data
											?.display_name !== "undefined"
											? getPoolerWalletQuery.data?.data
													?.display_name
											: "N/A"}
									</p>
								</div>
								<div className="flex flex-col space-y-4 md:w-full mmd:flex-1 mmd:text-center">
									<div className="flex flex-col space-y-2.5">
										<p className="text-xs text-[#667085]">
											Account Name
										</p>
										<p
											className={`text-sm font-medium text-[#002060] ${
												getPoolerWalletQuery.isLoading &&
												"py-2"
											}`}
										>
											{getPoolerWalletQuery.isFetched &&
											getPoolerWalletQuery?.data?.data
												?.display_name !== undefined
												? getPoolerWalletQuery?.data
														?.data?.display_name
												: "N/A"}
										</p>
									</div>
									<div className="flex flex-col space-y-2.5">
										<p className="text-xs text-[#667085]">
											Account Number
										</p>
										<p
											className={`flex items-center justify-between text-sm font-medium text-[#002060] ${
												getPoolerWalletQuery.isLoading &&
												"py-2"
											}`}
										>
											{getPoolerWalletQuery.isFetched &&
											getPoolerWalletQuery?.data?.data
												?.account_no !== undefined &&
											getPoolerWalletQuery.data?.data
												?.account_no
												? toggleAccNum
													? getPoolerWalletQuery.data
															?.data?.account_no
													: getPoolerWalletQuery.data?.data?.account_no.replace(
															/(?<=.{3}).(?=.{3})/g,
															"*"
													  )
												: "N/A"}
											<i className="flex items-center text-[#002060]">
												<button
													type="button"
													onClick={() =>
														setToggleAccNum(
															!toggleAccNum
														)
													}
													aria-label={
														toggleAccNum
															? "Hide account number"
															: "Show account number"
													}
												>
													<span className="sr-only">
														{toggleAccNum
															? "Hide account number"
															: "Show account number"}
													</span>
													{toggleAccNum ? (
														<BsEyeFill />
													) : (
														<BsEyeSlashFill />
													)}
												</button>
											</i>
										</p>
									</div>
									<div className="flex flex-col space-y-2.5">
										<p className="text-xs text-[#667085]">
											Bank Name
										</p>
										<p
											className={`flex items-center justify-between text-sm font-medium text-[#002060] ${
												getPoolerWalletQuery.isLoading &&
												"py-2"
											}`}
										>
											{getPoolerWalletQuery.isFetched &&
											getPoolerWalletQuery?.data?.data
												?.bank_name !== undefined &&
											getPoolerWalletQuery.data?.data
												?.bank_name
												? toggleBankName
													? getPoolerWalletQuery.data
															?.data?.bank_name
													: getPoolerWalletQuery.data?.data?.bank_name.replace(
															/(?<=.{3}).(?=.{4})/g,
															"*"
													  )
												: "N/A"}
											<i className="flex items-center text-[#002060]">
												<button
													type="button"
													onClick={() =>
														setToggleBankName(
															!toggleBankName
														)
													}
													aria-label={
														toggleBankName
															? "Hide account number"
															: "Show account number"
													}
												>
													<span className="sr-only">
														{toggleBankName
															? "Hide account number"
															: "Show account number"}
													</span>
													{toggleBankName ? (
														<BsEyeFill />
													) : (
														<BsEyeSlashFill />
													)}
												</button>
											</i>
										</p>
									</div>
									<div className="flex items-center justify-between">
										<div className="flex flex-col space-y-2.5">
											<p className="text-xs text-[#667085]">
												Payment Code
											</p>

											<p className="text-sm font-medium text-[#002060]">
												{getPaymentCode || "-"}
											</p>
										</div>
										{!getPaymentCode && (
											<Button
												disabled={
													createPaymentCode.isLoading
												}
												onClick={() =>
													createPaymentCode.mutate()
												}
												additionalClasses="text-white max-w-[156px] text-[12px] h-12 font-medium flex-1"
											>
												Generate Payment code
											</Button>
										)}
									</div>
								</div>
								<div className="mt-6 flex w-full gap-5">
									<Button
										disabled={false}
										onClick={() => {
											setShowPaymentModal(true);
										}}
										additionalClasses="text-white font-medium flex-1 px-[22px]"
									>
										Transfer
									</Button>
									<Button
										disabled={false}
										onClick={() =>
											setShowAllSavedRecipients(true)
										}
										additionalClasses="text-[#002060] font-medium flex-1 bg-white border-[#072154] border px-[22px]"
									>
										Beneficiaries
									</Button>
								</div>
							</div>
							<RequestIsLoading
								isLoading={
									getAccountInfoQuery.isLoading ||
									getPoolerWalletQuery.isLoading
								}
							/>
						</aside>
					</section>
					<div className="mt-4 rounded-md bg-white px-6 py-[18px] drop-shadow-md sm:mt-8">
						<div className="flex items-center justify-between font-semibold text-[#002060]">
							<h1 className="font-semibold">
								Transaction History
							</h1>
						</div>
						{/* {isLoading && (
							<div className={`flex h-full`}>
								<div className="mx-auto mt-12 h-full scale-[2]">
									<Loader2 color="#061F6A" />
								</div>
							</div>
						)}
						{isError && (
							<div className=" flex h-full ">
								<div className="mx-auto mt-12 h-full">
									<h1>Cannot retrieve data</h1>
								</div>
							</div>
						)} */}
						<div className="mt-3 w-full overflow-x-auto">
							<div className="mb-4 flex items-center justify-between gap-6 rounded  py-0.5">
								<div className="flex items-center gap-4">
									<p className="text-[14px] font-[500] text-gray-800">
										Filters
									</p>
									<button
										onClick={() => sortData("date")}
										className={
											"inline-flex items-center gap-2 rounded border border-[#BABFC3] px-3 py-1.5 text-[14px] text-[#8D9BB8] "
										}
									>
										<span className="text-[12px]">
											Date
										</span>

										{getSortIcon("date")}
									</button>

									<Select
										className="text-[12px]"
										placeholder="Transaction Type"
										onChange={(selectedOption) => {
											sortStatusData(
												"transaction_type",
												selectedOption.value
											);
										}}
										styles={{
											control: (base) => ({
												...base,
												height: 33,
												minHeight: 33,
												color: "#8D9BB7",
											}),
											input: (base) => ({
												...base,
												color: "#8D9BB7",
											}),
											placeholder: (base) => ({
												...base,
												color: "#8D9BB7",
											}),
										}}
										options={[
											{
												label: "Credit",
												value: "credit",
											},
											{
												label: "Debit",
												value: "debit",
											},
										]}
									/>
								</div>
								<button
									onClick={handleExportData}
									className="inline-flex items-center gap-2 rounded border border-[#BABFC3] bg-transparent px-3 py-1.5 text-[14px] text-[#8D9BB8]"
								>
									<BsFillVinylFill className="text-blue-950" />
									<span className="text-[14px]">Export</span>
								</button>
							</div>
							<div className="overflow-x-auto">
								<table
									className={` w-full table-auto whitespace-nowrap text-[14px] ${
										getTransactionHistory.isFetched &&
										getTransactionHistory?.data?.data
											?.length < 1 &&
										"hidden"
									}`}
								>
									<thead>
										<tr className=" rounded-md border-b border-t border-b-slate-400 border-t-slate-400 font-medium text-blue-950">
											<th className="px-3 py-2 text-left">
												Date
											</th>
											<th className="px-3 py-2 text-left font-medium">
												Transaction ID
											</th>
											<th className="px-3 py-2 text-left font-medium">
												Retailer’s Name
											</th>
											<th className="px-3 py-2 text-left font-medium">
												Amount
											</th>
											<th className="px-3 py-2 text-left font-medium">
												Recipient Name
											</th>
											<th className="px-3 py-2 text-left font-medium">
												Recipient Bank
											</th>
											<th className="px-3 py-2 text-left font-medium">
												Transaction Type
											</th>
											<th className="px-3 py-2 text-left font-medium">
												Status
											</th>
										</tr>
									</thead>
									<tbody className="text-center">
										{tableData
											.slice(0, 7)
											.map((data: any, index: number) => (
												<tr
													key={index}
													className="cursor-pointer border border-x-0 border-b-[#CBD5E1] text-slate-700 hover:bg-gray-100 focus:bg-gray-100"
													onClick={() =>
														handleRowClick(data)
													}
												>
													<td className="flex items-center px-3 py-2 text-sm capitalize">
														{formatDate(data.date)}
													</td>
													<td className="px-3 py-2 text-left">
														{data?.transaction_id?.slice(
															0,
															15
														) + "..."}
													</td>
													<td className="px-3 py-2 text-left">
														{data.retailer_name ??
															"N/A"}
													</td>
													<td className="px-3 py-2 text-left">
														<NumberFormat
															value={data.amount}
														/>
													</td>
													<td className="px-3 py-2 text-left">
														{data.recipient_name ??
															"N/A"}
													</td>
													<td className="px-3 py-2 text-left ">
														{data.recipient_bank ??
															"N/A"}
													</td>
													<td className="px-3 py-2 text-left ">
														<p
															className={`font-medium capitalize ${getStatusColor(
																data.transaction_type ??
																	"",
																"text"
															)}`}
														>
															{data?.transaction_type ??
																"N/A"}
														</p>
													</td>
													<td>
														<div
															className={`flex items-center gap-2 rounded-md bg-opacity-20 px-2 py-1 ${getStatusColor(
																data?.status ??
																	"",
																"background"
															)}`}
														>
															<span
																className={`h-2 w-2 rounded-full ${getStatusColor(
																	data?.status ??
																		"",
																	"background"
																)}`}
															></span>
															<p
																className={`font-semibold capitalize ${getStatusColor(
																	data?.status ??
																		"",
																	"text"
																)}`}
															>
																{data?.status ??
																	"N/A"}
															</p>
														</div>
													</td>
												</tr>
											))}
									</tbody>
								</table>
							</div>
							<p>
								{getTransactionHistory.isFetched &&
									getTransactionHistory?.data?.data.length <
										1 &&
									"No transactions have been made"}
							</p>
							{/* {isLoading && ( */}
							{/* <div className="mt-3 flex w-full justify-center">
							<div className="">
								<Oval
									height={80}
									width={80}
									color="#072154"
									wrapperStyle={{}}
									wrapperClass=""
									visible={true}
									ariaLabel="oval-loading"
									secondaryColor="#072154"
									strokeWidth={2}
									strokeWidthSecondary={2}
								/>
							</div>
						</div> */}
							{/* // )} */}
							{/* <button
								// onClick={() =>
								// 	handleChangeOverviewTab(
								// 		"Disbursement History"
								// 	)
								// }
								className="mt-4 cursor-pointer text-gray-700 hover:underline"
							>
								View All
							</button> */}
							<Link
								to="/dashboard/hadi-pay/history"
								className="mt-4 inline-flex text-sm text-gray-700 hover:underline"
							>
								View all
							</Link>
						</div>
					</div>
				</main>
			</section>
			<SetNewPIN show={showPIN} setShow={setShowPIN} />
			<ChangeTransactionPIN
				show={showChangePIN}
				setShow={setShowChangePIN}
				setShowPaymentModal={setShowPaymentModal}
				changePinToken={changePinToken}
				setChangePinStage={setChangePinStage}
				changePinStage={changePinStage}
			/>
			<BankTransfer
				show={showPaymentModal}
				setShow={setShowPaymentModal}
				banks={getAllBanksQuery?.data?.data?.data}
				getBanksIsLoading={getAllBanksQuery.isLoading}
				walletBalance={getPoolerWalletQuery?.data?.data?.balance ?? 0}
				transactionAttempt={transactionAttempt}
				setTransactionAttempt={setTransactionAttempt}
				setShowChangePIN={setShowChangePIN}
				showChangePIN={showChangePIN}
				setChangePinToken={setChangePinToken}
				setChangePinStage={setChangePinStage}
			/>
			<AllSavedRecipients
				data={getBeneficiariesQuery.data?.data?.data}
				show={showAllSavedRecipients}
				setShow={setShowAllSavedRecipients}
			/>
			<TransactionDetails
				setShow={setShowTransactionDetails}
				show={showTransactionDetails}
				transactionDetailsData={transactionData}
			/>
		</>
	);
};

export default HadiPay;

interface HadiPayInsightProps {
	title: string;
	amount: string;
	isLoading: boolean;
	isFetched: boolean;
	iconImage?: React.ReactNode;
	footNote?: React.ReactNode;
}

const HadiPayInsight: React.FC<HadiPayInsightProps> = ({
	title,
	amount,
	isLoading,
	isFetched,
	iconImage,
	footNote,
}) => {
	return (
		<div className="relative flex min-h-[120px] flex-1 flex-col bg-white px-6">
			<div className="flex flex-col space-y-2">
				<div className="flex items-center justify-between">
					<h4 className="text-sm font-medium text-slate-600">
						{title}
					</h4>
					<figure className="self-end rounded-[8px] bg-[#F6F7F9] p-2.5">
						{iconImage ? iconImage : <RiWalletFill />}
					</figure>
				</div>
				<p
					className={`text-[30px] font-bold text-blue-950 ${
						isLoading && "py-3.5"
					}`}
				>
					{isFetched && amount !== undefined ? (
						<>
							<NumberFormat value={amount} />
							<span className="ml-[12px] text-[13px] text-[#90A3BF]">
								(NGN)
							</span>
						</>
					) : (
						"N/A"
					)}
				</p>
				{/* <p>{footNote ? footNote : ""}</p> */}
				{footNote ? footNote : ""}
			</div>
			<RequestIsLoading isLoading={isLoading} />
		</div>
	);
};

// const SavedRecipients: React.FC = () => {
// 	const [showAllSavedRecipients, setShowAllSavedRecipients] = useState(false);

// 	const getBeneficiariesOnSuccess = () => {
// 		//
// 	};

// 	const getBeneficiariesOnError = () => {
// 		//
// 	};
// 	const getBeneficiariesQuery = useGetAccountBeneficiaries(
// 		getBeneficiariesOnSuccess,
// 		getBeneficiariesOnError
// 	);
// 	return (
// 		<>
// 			<aside className="relative flex min-h-[120px] flex-1 flex-col rounded-[10px] bg-white p-6 drop-shadow-md">
// 				<div className="flex items-center justify-between space-x-2">
// 					<p className="font-semibold text-blue-950">
// 						Saved Recipients
// 					</p>
// 					<p
// 						onClick={() => setShowAllSavedRecipients(true)}
// 						className="cursor-pointer text-xs text-slate-700 hover:underline"
// 					>
// 						View More
// 					</p>
// 				</div>
// 				<div
// 					className={`flex flex-col space-y-3 pt-4 ${
// 						getBeneficiariesQuery.isLoading && "py-12"
// 					}`}
// 				>
// 					{getBeneficiariesQuery.isFetched &&
// 						getBeneficiariesQuery.data?.data?.data.length === 0 && (
// 							<p>No beneficiaries have been saved</p>
// 						)}
// 					{getBeneficiariesQuery.isFetched &&
// 						getBeneficiariesQuery.data?.data?.data.map(
// 							(beneficiary: BeneficiaryData, i: number) => (
// 								<SavedRecipientItem
// 									key={i}
// 									account_name={beneficiary.account_name}
// 									account_number={beneficiary.account_number}
// 									bank_name={beneficiary.bank_name}
// 									bank_code={beneficiary.bank_code}
// 								/>
// 							)
// 						)}
// 					<RequestIsLoading
// 						isLoading={getBeneficiariesQuery.isLoading}
// 					/>
// 				</div>
// 			</aside>
// 			<AllSavedRecipients
// 				data={getBeneficiariesQuery.data?.data?.data}
// 				show={showAllSavedRecipients}
// 				setShow={setShowAllSavedRecipients}
// 			/>
// 		</>
// 	);
// };

// const SavedRecipientItem: React.FC<BeneficiaryData> = ({
// 	account_name,
// 	account_number,
// }) => {
// 	return (
// 		<li className="flex items-center gap-2">
// 			<figure className="flex h-7 w-7 select-none items-center justify-center rounded-full bg-[#33E57A] bg-opacity-30 text-xs font-medium">
// 				{letter_profile_icon(account_name)}
// 			</figure>
// 			<div className="flex-1 text-xs text-blue-950">
// 				<p className="font-semibold">{toTitleCase(account_name)}</p>
// 				<small className="font-medium">
// 					{starred_account_number(account_number)}
// 				</small>
// 			</div>
// 		</li>
// 	);
// };
