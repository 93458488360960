import {
	MutationFunction,
	useMutation,
	useQuery,
	useQueryClient,
} from "react-query";
import {
	applyForCredit,
	customerCancelCredit,
	customerDeclineCredit,
	cancelDistributorCredit,
	finalAcceptCredit,
	guarantorAccept,
	guarantorDeclineCredit,
	guarantorVerify,
	initialAcceptCredit,
	sendOTP,
	verifyCredit,
	distributorConfirmRetailerLoan,
	applyForDistributorCredit,
	retailerAuthorizeCredit,
	retailerForbidCredit,
	verifyRetailerCredit,
	retailerGuarantorVerify,
	retailerAuthorization,
	retailerGuarantorVerifyV2,
	guarantorAcceptV2,
} from "../api/credit";
import { AxiosError, AxiosResponse } from "axios";
import { Toast } from "../utils/toast";
import { logDev } from "../utils/utilities";
import {
	CreditFormData,
	GuarantorCreditAcceptFormV2Props,
} from "../types/views";

export const useApplyForCredit = (
	onSuccess: (data: AxiosResponse) => void,
	onError: (data: AxiosResponse) => void
) => {
	const queryClient = useQueryClient();
	const { mutate, ...rest } = useMutation(applyForCredit, {
		onSuccess,
		onError,
		onSettled: () => {
			queryClient.invalidateQueries(["distributor-credit"]);
			//invalidate and refecth query to be implemented
			// queryClient.refetchQueries("")
		},
	});
	return { mutateApplyForCredit: mutate, ...rest };
};
export const useApplyForDistributorCredit = (
	onSuccess: (data: AxiosResponse) => void,
	onError: (data: AxiosError) => void
) => {
	const { mutate, ...rest } = useMutation(applyForDistributorCredit, {
		onSuccess,
		onError,
	});
	return { mutateApplyForDistributorCredit: mutate, ...rest };
};

export const useCustomerCancelCredit = (
	onSuccess: (data: AxiosResponse) => void,
	onError: (data: AxiosResponse) => void
) => {
	const { mutate, ...rest } = useMutation(customerCancelCredit, {
		onSuccess,
		onError,
	});
	return { mutateCancelCredit: mutate, ...rest };
};

export const useCustomerDeclineCredit = (
	onSuccess: (data: AxiosResponse) => void,
	onError: (data: AxiosResponse) => void
) => {
	const { mutate, ...rest } = useMutation(customerDeclineCredit, {
		onSuccess,
		onError,
	});
	return { mutateDeclineCredit: mutate, ...rest };
};

export const useGuarantorDeclineCredit = (
	onSuccess: (data: AxiosResponse) => void = () => {
		return;
	},
	onError: (data: AxiosResponse) => void
) => {
	const { mutate, ...rest } = useMutation(guarantorDeclineCredit, {
		onSuccess,
		onError,
	});
	return { mutateGuarantorDeclineCredit: mutate, ...rest };
};

export const useVerifyCredit = (
	onSuccess: (data: AxiosResponse) => void = () => {
		return;
	},
	onError: (data: AxiosResponse) => void
) => {
	const { mutate, ...rest } = useMutation(verifyCredit, {
		onSuccess,
		onError,
	});
	return { mutateVerifyCredit: mutate, ...rest };
};

export const useRetailerVerifyCredit = (
	auth: string,
	onSuccess: (data: AxiosResponse) => void,
	onError: (data: AxiosError) => void
) => {
	return useQuery(["verify-credit", auth], verifyRetailerCredit, {
		onSuccess: (data) => onSuccess(data),
		onError: (data: AxiosError) => onError(data),
		enabled: false,
		retry: 3,
	});
};

export const useInitialCreditAccept = (
	onSuccess: (data: AxiosResponse) => void,
	onError: (data: AxiosError) => void,
	token: string | null
) => {
	const mutation = useMutation<
		AxiosResponse<any, any>,
		AxiosError<any, any>,
		CreditFormData | null,
		unknown
	>((formData) => initialAcceptCredit(formData, token), {
		onError,
		onMutate: (formData: CreditFormData | null) => {
			return formData;
		},
		onSuccess,
	});

	return { mutateInitialAcceptCredit: mutation.mutate, ...mutation };
};

//New endpoint to replace useInitialCreditAccept
export const useRetailerAuthorizeCredit = (
	onSuccess: (data: AxiosResponse) => void,
	onError: (data: AxiosError) => void
) => {
	const { mutate, ...rest } = useMutation(retailerAuthorizeCredit, {
		onSuccess,
		onError,
	});
	return { mutateRetailerAuthorizeCredit: mutate, ...rest };
};

// New endpoint to replace useCustomerCancelCredit
export const useRetailerForbidCredit = (
	onSuccess: (data: AxiosResponse) => void,
	onError: (data: AxiosResponse) => void
) => {
	const { mutate, ...rest } = useMutation(retailerForbidCredit, {
		onSuccess,
		onError,
	});
	return { mutateRetailerForbidCredit: mutate, ...rest };
};

export const useSendVerifyOTP = (
	onSuccess: (data: AxiosResponse) => void,
	onError: (data: AxiosResponse) => void
) => {
	const { mutate, ...rest } = useMutation(sendOTP, {
		onSuccess: (data: AxiosResponse) => {
			onSuccess(data);
			Toast.success("OTP has been sent kindly check your phone");
		},
		onError: (error: AxiosResponse) => {
			onError(error);
			Toast.error("OTP could not be sent. Try again!");
		},
	});
	return { mutateSendOTP: mutate, ...rest };
};

export const useResendVerifyOTP = (
	onSuccess: (data: AxiosResponse) => void,
	onError: (data: AxiosResponse) => void
) => {
	const { mutate, ...rest } = useMutation(sendOTP, {
		onSuccess: (data: AxiosResponse) => {
			onSuccess(data);
			logDev("OTP has been sent kindly check your phone");
		},
		onError: (error: AxiosResponse) => {
			onError(error);
			Toast.error("OTP could not be sent. Try again!");
		},
	});
	return { mutateResendSendOTP: mutate, ...rest };
};

export const useFinalAcceptCredit = (
	onSuccess: (data: AxiosResponse) => void,
	onError: (data: AxiosResponse) => void,
	token: string | null
) => {
	const mutation = useMutation<
		AxiosResponse<any, any>,
		AxiosResponse<any, any>,
		any,
		unknown
	>((formData) => finalAcceptCredit(formData, token), {
		onError: (error: AxiosResponse) => {
			onError(error);
			Toast.error("The code you enetered is incorrect!");
		},
		onMutate: (formData: CreditFormData) => {
			return formData;
		},
		onSuccess: (data: AxiosResponse) => {
			onSuccess(data);
			Toast.success("Credit has been offered!");
		},
	});

	return { mutateFinalAcceptCredit: mutation.mutate, ...mutation };
};
export const useGuarantorAccept = (
	onSuccess: (data: AxiosResponse) => void,
	onError: (data: AxiosResponse) => void,
	token: string | null
) => {
	const mutation = useMutation<
		AxiosResponse<any, any>,
		AxiosResponse<any, any>,
		any,
		unknown
	>((formData) => guarantorAccept(formData, token), {
		onError,
		onMutate: (formData: CreditFormData) => {
			return formData;
		},
		onSuccess,
	});

	return { mutateGuarantorAccept: mutation.mutate, ...mutation };
};

export const useGuarantorAcceptV2 = (
	onSuccess: (data: AxiosResponse) => void,
	onError: (data: AxiosError) => void,
	token: string | null
) => {
	const mutation = useMutation<
		AxiosResponse<any, any>,
		AxiosError<any, any>,
		any,
		unknown
	>((formData) => guarantorAcceptV2(formData, token), {
		onError,
		onMutate: (formData: GuarantorCreditAcceptFormV2Props) => {
			return formData;
		},
		onSuccess,
	});

	return { mutateGuarantorAccept: mutation.mutate, ...mutation };
};

// export const useGuarantorVerify = (
// 	onSuccess: (data: AxiosResponse) => void = () => {
// 		return;
// 	},
// 	onError: (data: AxiosError) => void,
// ) => {
// 	const { mutate, ...rest } = useMutation(guarantorVerify(auth, requestType), {
// 		onSuccess,
// 		onError,
// 	});
// 	return { mutateGuarantorVerify: mutate, ...rest };
// };

export const useGuarantorVerify = (
	onSuccess: (data: AxiosResponse) => void = () => {
		return;
	},
	onError: (data: AxiosError) => void
) => {
	const mutationFn: MutationFunction<
		AxiosResponse,
		[string | null, string | null]
	> = ([auth, requestType]) => {
		return guarantorVerify(auth, requestType);
	};

	const { mutate, ...rest } = useMutation(mutationFn, {
		onSuccess,
		onError,
	});

	return { mutateGuarantorVerify: mutate, ...rest };
};

export const useRetailerGuarantorVerify = (
	auth: string,
	onSuccess: (data: AxiosResponse) => void,
	onError: (data: AxiosError) => void
) => {
	return useQuery(
		["guarantor-retailer-verify", auth],
		retailerGuarantorVerify,
		{
			onSuccess: (data) => onSuccess(data),
			onError: (data: AxiosError) => onError(data),
			enabled: false,
			retry: 3,
		}
	);
};

export const useRetailerGuarantorVerifyV2 = (
	auth: string,
	onSuccess: (data: AxiosResponse) => void,
	onError: (data: AxiosError) => void
) => {
	return useQuery(
		["guarantor-retailer-verify-v2", auth],
		retailerGuarantorVerifyV2,
		{
			onSuccess: (data) => onSuccess(data),
			onError: (data: AxiosError) => onError(data),
			enabled: false,
			retry: 3,
		}
	);
};

export const useCancelDistributorCredit = (
	onSuccess: (data: AxiosResponse) => void,
	onError: (data: AxiosError) => void
) => {
	const queryClient = useQueryClient();
	const { mutate, ...rest } = useMutation(cancelDistributorCredit, {
		onSuccess: (data: AxiosResponse) => {
			onSuccess(data);
		},
		onError: (error: AxiosError) => {
			onError(error);
		},
		onSettled: () => {
			queryClient.invalidateQueries(["distributor-credit"]);
		},
	});
	return { mutateCancelDistributorCredit: mutate, ...rest };
};

export const useDistributorConfirmCredit = (
	onSuccess: () => void,
	onError: () => void
) => {
	const { mutate, ...rest } = useMutation(distributorConfirmRetailerLoan, {
		onSuccess: () => {
			onSuccess();
		},
		onError: () => {
			onError();
		},
	});
	return { mutateDistributorConfirmCredit: mutate, ...rest };
};

export const useRetailerAuthorization = (
	onSuccess: (data: AxiosResponse) => void,
	onError: (data: AxiosError) => void
) => {
	const { mutate, ...rest } = useMutation(retailerAuthorization, {
		onSuccess,
		onError,
	});
	return { mutateRetailerAuthorization: mutate, ...rest };
};
