import React from "react";
import { useField } from "formik";
import {
	Button,
	FormikInput,
	ProcessUploadFile,
	UploadedFile,
} from "../../../../components";
import { AnimatePresence, motion } from "framer-motion";
// import { Gender } from "../../services/Constants";
import { ArrowLeft } from "iconsax-react";
import { getState, getStateLga } from "../../../../utils/utilities";
import { uploadedFileContainerVariants } from "../../../../services/Animations";

interface Form2Props {
	setActiveStep: React.Dispatch<React.SetStateAction<number>>;
}

const GuarantorFormStep2: React.FC<Form2Props> = ({ setActiveStep }) => {
	const [field, meta, helpers] = useField("utility_document");
	const [nameField, ,] = useField("utility_documentName");
	const [stateField, ,] = useField("state");
	return (
		<>
			<div className="mb-7 flex w-full max-w-[640px] flex-col items-start justify-center space-y-7 ">
				<Button
					disabled={false}
					additionalClasses="text-[#07122F] bg-transparent w-auto leading text-lg flex items-center gap-4"
					onClick={() => setActiveStep(0)}
				>
					<ArrowLeft color="#07122F" />
					<span>Guarantor’s Form</span>
				</Button>
				<div className="flex w-full items-center justify-center">
					<div className="flex items-center font-bold">
						<div className="flex h-10 w-10 items-center justify-center rounded-full bg-[#1A4393] text-white">
							1
						</div>
						<div className="h-px w-16 bg-[#1A4393]"></div>
						<div className="flex h-10 w-10 items-center justify-center rounded-full bg-[#EEF9FF] text-[#1A4393]">
							2
						</div>
					</div>
				</div>
			</div>
			<div className="mb-2 inline h-full w-full max-w-[640px] flex-1 flex-col items-start justify-center space-y-6 overflow-y-auto pb-2 pr-2">
				<FormikInput
					label="Phone Number"
					labelStyles="!text-[#596780] !font-normal"
					name="contact_phone"
					id="contact_phone"
					type="text"
					minLength={11}
					maxLength={11}
					placeholder="Enter your phone number"
					autoComplete={"false"}
					additionalClasses="border-[#263238] placeholder-[#263238] !px-4"
					containerStyle="col-span-2"
					required={true}
				/>
				<FormikInput
					label="BVN"
					labelStyles="!text-[#596780] !font-normal"
					name="bvn"
					id="bvn"
					type="text"
					minLength={11}
					maxLength={11}
					placeholder="Enter your BVN"
					autoComplete={"false"}
					additionalClasses="border-[#263238] placeholder-[#263238] !px-4"
					required={true}
				/>
				<FormikInput
					label="NIN"
					labelStyles="!text-[#596780] !font-normal"
					name="nin"
					id="nin"
					type="text"
					minLength={11}
					maxLength={11}
					placeholder="Enter your NIN"
					autoComplete={"false"}
					additionalClasses="border-[#263238] placeholder-[#263238] !px-4"
					required={true}
				/>
				<FormikInput
					options={getState()}
					label="State"
					labelStyles="!text-[#596780] !font-normal"
					name="state"
					id="gender"
					selectValueKey="value"
					type="select"
					placeholder="State"
					autoComplete={"false"}
					additionalClasses="border-[#263238] placeholder-[#263238] !px-4"
					selectStyleOptions={{ paddingLeft: "8px" }}
					required={true}
				/>
				<FormikInput
					options={getStateLga(stateField.value)}
					label="LGA"
					labelStyles="!text-[#596780] !font-normal"
					name="lga"
					id="lga"
					selectValueKey="value"
					type="select"
					placeholder="State"
					autoComplete={"false"}
					additionalClasses="border-[#263238] placeholder-[#263238] !px-4"
					selectStyleOptions={{ paddingLeft: "8px" }}
					required={true}
				/>
				<div className="flex w-full flex-col">
					<div className="-mb-1 flex flex-col text-left">
						<h2 className="font-normal text-[#596780]">
							Upload Utility Bill
						</h2>
					</div>
					<ProcessUploadFile
						formats={[".jpeg", ".jpg", ".png"]}
						maxSize={5 * 1024 * 1024}
						name="utility_document"
						localStorageFileName={"utility_document"}
					/>
					<AnimatePresence>
						{field.value && (
							<motion.div
								className="flex w-full flex-col gap-4"
								variants={uploadedFileContainerVariants}
								initial="hidden"
								animate="visible"
								exit="exit"
							>
								<h4 className="w-full text-[#676767]">
									Uploaded file(s)
								</h4>
								<div className="flex flex-col gap-3">
									<UploadedFile
										title={nameField.value}
										deleteFile={() => {
											helpers.setValue(null);
										}}
									/>
								</div>
							</motion.div>
						)}
					</AnimatePresence>
					{meta.touched && meta.error ? (
						<p className="mt-2 text-xs text-error-600">
							{meta.error}
						</p>
					) : null}
				</div>
				{/* <FormikInput
					label={
						<>
							I accept the{" "}
							<a
								href="https://www.hadifinance.com/terms-and-conditions"
								rel="noreferrer"
								target="_blank"
								className="text-primary-700"
							>
								Terms & Conditions
							</a>
						</>
					}
					name="terms"
					id="terms"
					type="checkbox"
					additionalClasses="border-[#263238] placeholder-[#263238] !px-4"
					containerStyle="-mt-6 col-span-2"
					required={true}
				/> */}
			</div>
			<div className="mb-7 flex w-full max-w-[640px] flex-col items-start justify-center space-y-7 ">
				<Button
					disabled={false}
					type="submit"
					additionalClasses="text-lg text-white font-medium bg-primary-800 mt-[14px] lg:mt-[27px] col-span-2"
				>
					Next
				</Button>
			</div>
		</>
	);
};

export default GuarantorFormStep2;
