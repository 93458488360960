import ReactGA from "react-ga4";
import { GOOGLE_ANALYTICS_ID } from "./services/Constants";

export const initGA = () => {
	if (GOOGLE_ANALYTICS_ID) {
		ReactGA.initialize(GOOGLE_ANALYTICS_ID);
	}
};

export const logPageView = () => {
	ReactGA.send({ hitType: "pageview", page: window.location.pathname });
};
