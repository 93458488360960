import { Link } from "react-router-dom";
import Logo from "../../assets/image/hadiSvgLogo.svg";
import { Button } from "components";
import { Toast } from "utils/toast";
import RequestIsLoading from "components/RequestIsLoading";
import { IoCopyOutline } from "react-icons/io5";
import { CiSearch } from "react-icons/ci";
import { useFetchCodes } from "../../hooks/useFetchCodes";

const AccountOfficer = () => {
	const {
		formData,
		setFormData,
		showData,
		activationCode,
		handleGetCodes,
		isLoading,
	} = useFetchCodes();

	const copyToClipboard = async () => {
		try {
			if (activationCode) {
				await navigator.clipboard.writeText(activationCode);
				Toast.success("Activation Code copied to clipboard");
			}
		} catch (err) {
			Toast.error("Failed to copy content");
		}
	};
	return (
		<div className="mx-auto flex min-h-[100vh] w-full flex-col items-center pb-8 pt-5">
			<div className="w-full p-3">
				<Link to="/" className="w-max ">
					<figure className="h-8 w-28">
						<img src={Logo} alt="Hadi logo" />
					</figure>
				</Link>
			</div>
			<div className="justify-cente backdrop-filterr mt-20 flex h-full w-full max-w-[505px] flex-col items-center rounded-[8px] bg-white shadow-sm backdrop-blur-lg">
				<header className="space-y-2 px-5 py-3 text-center">
					<h3 className="text-[24px] font-semibold text-blue-950">
						Dear Hadi Account Officer,
					</h3>
					<p className="text-[16px] font-medium text-[#90A3BF]">
						Kindly input your phone number to get activation code
					</p>
				</header>
				<form encType="multipart/form-data" className="w-full">
					<div className="m-5 mt-[15px] space-y-5 text-[14px] text-slate-500">
						<div className="flex w-full items-center rounded-[8px] border border-[#90A3BF] px-3">
							<input
								name="phone_number"
								id="phone_number"
								className="focus-none h-full w-full py-3 text-[14px] font-medium text-black placeholder-[#263238] placeholder-opacity-50 outline-none"
								value={formData.phone_number}
								placeholder="Input phone number"
								minLength={10}
								maxLength={11}
								type="tel"
								required={false}
								autoComplete="false"
								onChange={(
									e: React.ChangeEvent<HTMLInputElement>
								) => {
									const value = e.target.value.replace(
										/\D/g,
										""
									);
									setFormData({
										...formData,
										phone_number: value,
									});
								}}
							/>

							<CiSearch className="h-[24px] w-[24px] text-black" />
						</div>
						{showData ? (
							<div className="flex h-full w-full flex-col rounded-[8px] bg-[#EEF9FF] px-5">
								<div className="space-y-2 border-b border-[#BAE5FF] py-5">
									<h4 className="text-[16px] font-medium text-[#1A4393]">
										Hello, Account Officer
									</h4>
									<p className="text-[14px] text-[#596780]">
										Your activation code below;
									</p>
								</div>
								<div className="flex w-full items-center justify-between py-5">
									<div className="flex flex-col gap-y-3">
										<p className="flex w-full items-center justify-center gap-3 text-[14px] font-bold text-[#1A4393] transition-colors duration-300 hover:text-slate-700">
											Activation Code: {activationCode}
										</p>
										<span
											onClick={copyToClipboard}
											className="flex cursor-pointer items-center gap-2 text-[12px] text-[#596780] underline "
										>
											Copy activation code{" "}
											<IoCopyOutline className="text-[12px] text-[#292D32]" />
										</span>
									</div>
								</div>
							</div>
						) : (
							<div className="mx-auto flex w-[90%] flex-col items-center gap-4">
								<Button
									additionalClasses="text-[18px] font-semibold bg-[#2A60B7] w-full text-white"
									disabled={isLoading}
									onClick={handleGetCodes}
								>
									Search
								</Button>
							</div>
						)}
						<RequestIsLoading isLoading={isLoading} />
					</div>
				</form>
			</div>
		</div>
	);
};

export default AccountOfficer;
