import React from "react";
import { Loader2 } from "../../../../components";
// import { Gender } from "../../services/Constants";

interface FormLoadingProps {
	verifyLoading: boolean;
}

const GuarantorLoading: React.FC<FormLoadingProps> = ({
	verifyLoading = true,
}) => {
	return (
		<>
			{verifyLoading && (
				<div
					className={`fixed inset-0 flex scale-[2] items-center justify-center bg-white bg-opacity-60 `}
				>
					<Loader2 color="#061F6A" />
				</div>
			)}
		</>
	);
};

export default GuarantorLoading;
