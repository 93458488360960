import React, { useEffect, useState, Fragment } from "react";
import Form1 from "./Form1";
import Form2 from "./Form2";
import { FormData } from "types/views";
import { useNavigate } from "react-router";
import { useRegisterDistributor } from "../../hooks/useAuthData";
import { SuccessModal } from "../../components";
import { useAuth } from "../../hooks/auth";
import { Toast } from "../../utils/toast";
import { AxiosError, AxiosResponse } from "axios";
import { useGetBusinessSectors } from "../../hooks/useCustomerData";
import { Link } from "react-router-dom";
import blackLogo from "../../assets/image/blackLogo.svg";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Patterns } from "../../services/Constants";
import mixpanel from "mixpanel-browser";

const Register = () => {
	const navigate = useNavigate();
	const { user } = useAuth();
	const [showSuccessModal, setShowSuccessModal] = useState(false);
	const [activeStep, setActiveStep] = useState<number>(0);
	const [formData] = useState<FormData>({
		firstName: "",
		lastName: "",
		gender: "",
		phoneNumber: "",
		email: "",
		address: "",
		businessName: "",
		businessPhoneNumber: "",
		businessEmailAddress: "",
		businessSector: "",
		password: "",
		confirmPassword: "",
		terms: false,
	});

	const validationSchema = [
		Yup.object({
			phoneNumber: Yup.string()
				.matches(Patterns.telephone, "Invalid phone number format")
				.required("Phone number is required"),
			firstName: Yup.string()
				.min(2, "Invalid first name")
				.required("First name is required"),
			lastName: Yup.string()
				.min(2, "Invalid last name")
				.required("Last name is required"),
			gender: Yup.string().required("Gender is required"),
			email: Yup.string()
				.required("Email is required")
				.email("Invalid email address"),
			address: Yup.string().required("Address is required"),
		}),

		Yup.object({
			phoneNumber: Yup.string()
				.matches(Patterns.telephone, "Invalid phone number format")
				.required("Phone number is required"),
			firstName: Yup.string()
				.min(2, "Invalid first name")
				.required("First name is required"),
			lastName: Yup.string()
				.min(2, "Invalid last name")
				.required("Last name is required"),
			gender: Yup.string().required("Gender is required"),
			email: Yup.string()
				.required("Email is required")
				.email("Invalid email address"),
			address: Yup.string().required("Address is required"),
			businessName: Yup.string()
				.required("Business name is required")
				.min(2, "Invalid business name"),
			businessPhoneNumber: Yup.string()
				.matches(Patterns.telephone, "Invalid phone number format")
				.required("Business phone number is required"),
			// businessEmailAddress: Yup.string()
			// 	.required("Business email is required")
			// 	.email("Invalid email address"),
			businessSector: Yup.string().required(
				"Business sector is required"
			),
			password: Yup.string()
				.required("Password is required")
				.min(8, "Password must be at least 8 characters"),
			confirmPassword: Yup.string()
				.oneOf([Yup.ref("password"), null], "Passwords must match")
				.required("Confirm password is required"),
			terms: Yup.boolean().oneOf(
				[true],
				"Accept the terms and conditions"
			),
		}),
	];

	const currentValidationSchema = validationSchema[activeStep];

	const [businessSectors, setBusinessSectors] = useState([
		{ name: "", description: "" },
	]);

	useEffect(() => {
		if (user) {
			navigate("/dashboard");
		}
	}, [navigate, user]);

	const showPreviousPage = () => {
		setActiveStep(activeStep - 1);
	};

	const onSuccess = () => {
		setShowSuccessModal(true);
		setTimeout(() => {
			navigate("/login");
		}, 4000);
		mixpanel.track("Sign Up");
	};

	const onError = (error: any) => {
		if (
			error &&
			error.response &&
			error.response.data &&
			error.response.data.error
		) {
			(error.response.data.error as any[]).forEach(
				(errorObj: any, index: number) => {
					setTimeout(() => {
						Object.values(errorObj).forEach((messages: any) => {
							messages.forEach((message: string) => {
								Toast.error(message);
							});
						});
					}, index * 4000);
				}
			);
		} else if (
			error &&
			error.response &&
			error.response.data &&
			error.response.data.message
		) {
			Toast.error(error.response.data.message);
		} else {
			Toast.error(error.message || "An error occurred, please try again");
		}
	};

	const { mutate: registerDistributor, isLoading } = useRegisterDistributor(
		onSuccess,
		onError
	);

	const handleSubmit = async (payload: FormData, actions: any) => {
		if (activeStep === 0) {
			setActiveStep(activeStep + 1);
			actions.setTouched({});
			actions.setSubmitting(false);
			return;
		} else {
			const newFormData = {
				username: payload.phoneNumber,
				email: payload.email,
				password1: payload.password,
				password2: payload.confirmPassword,
				gender: payload.gender,
				name: payload.firstName + " " + payload.lastName,
				address: payload.address,
				distributor: {
					name: payload.businessName,
					email: payload.businessEmailAddress,
					phone_number: payload.businessPhoneNumber,
					business_sector: payload.businessSector,
				},
			};
			registerDistributor(newFormData);
		}
	};
	const getBusinessSectorsOnSuccess = (data: AxiosResponse) => {
		setBusinessSectors(data.data.data.business_sectors);
	};
	const getBusinessSectorsOnError = (error: AxiosError) => {
		console.log(error);
	};
	useGetBusinessSectors(
		getBusinessSectorsOnSuccess,
		getBusinessSectorsOnError
	);

	const RenderStepContent = (activeStep: number) => {
		switch (activeStep) {
			case 0:
				return <Form1 />;
			case 1:
				return (
					<Form2
						isLoading={isLoading}
						handlePrevious={showPreviousPage}
						businessSectors={businessSectors}
					/>
				);
			default:
				return;
		}
	};

	return (
		<Fragment>
			<div className="item-center flex w-full justify-between mxs:flex-col mxs:items-center">
				<figure className="block h-8 w-28 lg:hidden">
					<img src={blackLogo} alt="Hadi logo" />
				</figure>
				<Link
					to="/login"
					className="flex w-full cursor-pointer items-center justify-end text-right text-[16px] font-[500] text-[#334155] mxs:justify-center mxs:text-center"
				>
					Already have an account?{" "}
					<span className="ml-1 cursor-pointer text-[#002EA9]">
						Sign in
					</span>
				</Link>
			</div>
			<div className="relative mx-auto flex w-full flex-1 items-center justify-center">
				<div className="flex h-full w-full flex-col items-center justify-center px-3 py-8">
					<div className="w-full max-w-[500px]">
						<h3 className="w-full text-left text-[32px] font-[600] leading-[1] text-primary-900 lg:leading-[56px]">
							Register business admin
						</h3>
						<p className="test-[14px] mb-[63px] font-[400] text-secondary-400">
							Please fill in required fields correctly
						</p>
						<Formik
							initialValues={formData}
							validationSchema={currentValidationSchema}
							onSubmit={handleSubmit}
						>
							{({ values }) => {
								return (
									<Form className="flex w-full flex-col gap-y-9">
										{RenderStepContent(activeStep)}
									</Form>
								);
							}}
						</Formik>
					</div>
				</div>
				<SuccessModal
					show={showSuccessModal}
					setShow={setShowSuccessModal}
					content="The Registration is successful, check your Email for more information"
				/>
			</div>
		</Fragment>
	);
};

export default Register;
