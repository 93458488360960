import React from "react";
import { Button, Loader2 } from "../../../../components";

interface Form1Props {
	verifyLoading: boolean;
	declineIsLoading: boolean;
	acceptLoading: boolean;
	handleDeclineCredit: () => void;
	retailerData: any;
}

const GuarantorFormStep: React.FC<Form1Props> = ({
	verifyLoading = true,
	declineIsLoading,
	acceptLoading,
	handleDeclineCredit,
	retailerData,
}) => {
	const closeWindow = () => {
		window.location.href = "https://hadifinance.com";
	};

	return (
		<>
			{verifyLoading && (
				<div
					className={`fixed inset-0 flex scale-[2] items-center justify-center bg-white bg-opacity-60 `}
				>
					<Loader2 color="#061F6A" />
				</div>
			)}
			{!verifyLoading ? (
				<>
					<div className="flex w-full max-w-[640px] flex-col items-end justify-center">
						<Button
							disabled={false}
							onClick={closeWindow}
							additionalClasses="text-red-500 underline bg-transparent w-auto leading"
						>
							Close
						</Button>
						<div className="mt-[15px] w-full">
							<h3 className="text-lg font-bold leading-7 text-[#07122F]">
								Hadi Finance Guarantor Agreement
							</h3>
							<p className="mt-1">
								Before you click on “Agreed”, please read and
								accept our Terms and Conditions.
							</p>
						</div>
					</div>
					<div className="mt-7 flex h-full w-full max-w-[640px] flex-1 flex-col gap-[18px] overflow-hidden rounded-md border-[0.5px] border-[#BAE5FF] bg-[#EEF9FF26] p-5">
						<div className="flex-1 overflow-y-auto pr-2">
							{retailerData?.guarantor_agreement ? (
								<div
									dangerouslySetInnerHTML={{
										__html: retailerData?.guarantor_agreement,
									}}
								/>
							) : (
								<div
									style={{
										maxWidth: "80%",
										margin: "0 auto",
									}}
								>
									<div
										style={{
											textAlign: "center",
											paddingTop: 20,
										}}
									>
										<h1>Guarantee and Indemnity</h1>
										<p>between</p>
										<h2>HADI AFRICA LIMITED</h2>
										<p>and</p>
										<h2>
											THE GUARANTOR WHOSE NAME AND DETAILS
											ARE INCLUDED IN THE SIGNATURE BLOCK
											AT THE BOTTOM OF THIS GUARANTEE AND
											INDEMNITY
										</h2>
									</div>
									<p style={{ paddingTop: 20 }}>
										This deed is dated as of the date
										included by the Guarantor in the
										Signature Block to this Guarantee and
										Indemnity.
									</p>
									<h3 style={{ paddingTop: 20 }}>Parties</h3>
									<ol>
										<p>
											<b>
												1. As stated in the Signature
												Block to this Guarantee and
												Indemnity (Guarantor)
											</b>
										</p>
										<p>
											<b> 2. HADI AFRICA LIMITED,</b>{" "}
											whose registered office is at 5SD8
											Lakeview Homes, Phase 2, Kado,
											Abuja, Nigeria <b> (Lender)</b>
										</p>
									</ol>
									<h3>Background</h3>
									<p>
										(A) The Lender has agreed to provide
										lending facilities to the Borrower under
										the following terms and as reflected in
										loan documents signed between the Lender
										and Borrower from time to time:
									</p>
									<ul style={{ listStyleType: "disc" }}>
										<li>
											Principal:Principal: Between NGN 1
											Naira (One Naira) and NGN 250,000
											(Two Hundred and Fifty Thousand
											Naira Only). Relevant Principal
											amount to be specified in loan
											documents between the Lender and
											Borrower from time to time.
										</li>
										<li>
											Tenor: As stated in the “Interest
											and Tenor” table below.
										</li>
										<li>Currency: Nigerian Naira (NGN).</li>
										<li>Interest and Tenor.</li>
									</ul>
									<table
										style={{
											width: "100%",
											borderCollapse: "collapse",
											marginTop: 20,
										}}
									>
										<tbody>
											<tr>
												<th
													style={{
														backgroundColor:
															"#f2f2f2",
														textAlign: "left",
														padding: 8,
														border: "1px solid #ddd",
													}}
												>
													Tenor
												</th>
												<th
													style={{
														backgroundColor:
															"#f2f2f2",
														textAlign: "left",
														padding: 8,
														border: "1px solid #ddd",
													}}
												>
													Interest Rate
												</th>
												<th
													style={{
														backgroundColor:
															"#f2f2f2",
														padding: 8,
														textAlign: "left",
														border: "1px solid #ddd",
													}}
												>
													Default Interest
												</th>
											</tr>
											<tr>
												<td
													style={{
														border: "1px solid #ddd",
														padding: 8,
														textAlign: "left",
													}}
												>
													14 Business Days
												</td>
												<td
													style={{
														border: "1px solid #ddd",
														padding: 8,
														textAlign: "left",
													}}
												>
													5%
												</td>
												<td
													style={{
														border: "1px solid #ddd",
														padding: 8,
														textAlign: "left",
													}}
												>
													5% (charged 14 days after
													due date on Principal
													Amount)
												</td>
											</tr>
											<tr>
												<td
													style={{
														border: "1px solid #ddd",
														padding: 8,
														textAlign: "left",
													}}
												>
													21 Business Days
												</td>
												<td
													style={{
														border: "1px solid #ddd",
														padding: 8,
														textAlign: "left",
													}}
												>
													7.5%
												</td>
												<td
													style={{
														border: "1px solid #ddd",
														padding: 8,
														textAlign: "left",
													}}
												>
													5% (charged 14 days after
													due date on Principal
													Amount)
												</td>
											</tr>
											<tr>
												<td
													style={{
														border: "1px solid #ddd",
														padding: 8,
														textAlign: "left",
													}}
												>
													28 Business Days
												</td>
												<td
													style={{
														border: "1px solid #ddd",
														padding: 8,
														textAlign: "left",
													}}
												>
													10%
												</td>
												<td
													style={{
														border: "1px solid #ddd",
														padding: 8,
														textAlign: "left",
													}}
												>
													5% (charged 14 days after
													due date on Principal
													Amount)
												</td>
											</tr>
										</tbody>
									</table>
									<p>
										(B) The Guarantor has agreed to enter
										into this guarantee and indemnity for
										the purpose of providing credit support
										to the Lender for the Borrower&apos;s
										liabilities from time to time
										outstanding to the Lender.
									</p>
									<section>
										<h3>Agreed Terms</h3>
										<h3>
											1. Definitions and Interpretation
										</h3>
										<p>1.1 Definitions</p>
										<p>
											The following definitions apply in
											this guarantee and indemnity:
										</p>
										<ul>
											<p>
												<strong>Borrower:</strong> As
												stated in the Signature Block of
												this Guarantee and Indemnity,
												and any successors, permitted
												assigns and permitted
												transferees.
											</p>
											<p>
												<strong>Business Day:</strong> a
												day including a Saturday, Sunday
												or public holiday in Nigeria.
											</p>
											<p>
												<strong>
													Guaranteed Obligations:
												</strong>{" "}
												all monies, debts and
												liabilities of any nature from
												time to time due, owing or
												incurred by the Borrower to the
												Lender on any current or other
												account under or in connection
												with any present or future
												banking or credit facilities
												provided by the Lender to the
												Borrower.
											</p>
											<p>
												<strong>Rights:</strong> any
												Security or any other right or
												benefit whether arising by way
												of set-off, counterclaim,
												subrogation, indemnity, proof in
												liquidation or otherwise and
												whether from contribution or
												otherwise.
											</p>
											<p>
												<strong>Security:</strong> any
												mortgage, charge (whether fixed
												or floating, legal or
												equitable), pledge, lien,
												assignment by way of security or
												other security interest securing
												any obligation of any person or
												any other agreement or
												arrangement having a similar
												effect.
											</p>
											<p>
												<strong>Tax:</strong> all forms
												of taxation and statutory,
												governmental, state, federal,
												provincial, local, government or
												municipal charges, duties,
												imposts, contributions, levies,
												withholdings or liabilities
												wherever chargeable and whether
												of the UK or anyother
												jurisdiction and any penalty,
												fine, surcharge, interest,
												charges or costs relating to
												them.
											</p>
										</ul>
										<div>
											<h3>1.2 Interpretation</h3>
											<p>
												In this guarantee and indemnity:
											</p>
											<ul>
												<p>
													(a) Clause headings shall
													not affect the
													interpretation of this
													guarantee;
												</p>
												<p>
													(b) a person includes an
													individual, firm, company,
													corporation, partnership,
													unincorporated body of
													persons, government, state
													or agency of a state or any
													association, trust, joint
													venture or consortium
													(whether or not having
													separate legal personality);
												</p>
												<p>
													(c) unless the context
													otherwise requires, words in
													the singular shall include
													the plural and in the plural
													shall include the singular;
												</p>
												<p>
													(d) unless the context
													otherwise requires, a
													reference to one gender
													shall include a reference to
													the other genders;
												</p>
												<p>
													(e) a reference to{" "}
													<b>the Lender</b> shall
													include the Lender&apos;s
													successors, permitted
													assigns and permitted
													transferees;
												</p>
												<p>
													(f) a reference to a statute
													or statutory provision is a
													reference to it as amended,
													extended or re-enacted from
													time to time;
												</p>
												<p>
													(g) a reference to a statute
													or statutory provision shall
													include all subordinate
													legislation made from time
													to time under that statute
													or statutory provision;
												</p>
												<p>
													(h) a reference to{" "}
													<b>writing</b> or{" "}
													<b>written</b> includes
													e-mail;
												</p>
												<p>
													(i) a reference to{" "}
													<b>this guarantee</b> (or
													any provision of it) is to
													this guarantee and indemnity
													(or a provision of it), and
													such a reference or a
													reference to any other
													agreement or document
													referred to in this
													guarantee and indemnity is a
													reference to this guarantee
													and indemnity, that
													provision or such other
													agreement or document as
													amended (in each case, other
													than in breach of the
													provisions of this guarantee
													and indemnity) from time to
													time;
												</p>
												<p>
													(j) unless the context
													otherwise requires, a
													reference to a clause is to
													a clause of this guarantee;
												</p>
												<p>
													(k) any words following the
													terms
													<b>
														{" "}
														including, include, in
														particular, for example{" "}
													</b>{" "}
													or any similar expression
													shall be construed as
													illustrative and shall not
													limit the sense of the
													words, description,
													definition, phrase or term
													preceding those terms;
												</p>
												<p>
													(l) a reference to an{" "}
													<b>amendment</b> includes a
													novation, supplement or
													variation (and <b>amend</b>{" "}
													and <b>amended</b> shall be
													construed accordingly);
												</p>
												<p>
													(m) a reference to{" "}
													<b>assets</b> includes
													present and future
													properties, undertakings,
													revenues, rights and
													benefits of every
													description;
												</p>
												<p>
													(n) a reference to an{" "}
													<b>authorisation</b>{" "}
													includes a consent,
													approval, resolution,
													licence, exemption, filing,
													notarisation or
													registration;
												</p>
												<p>
													(o) a reference to a{" "}
													<b>determination</b> means,
													unless the contrary is
													indicated, a{" "}
													<b>determination</b> made at
													the discretion of the person
													making it (and determine
													shall be construed
													accordingly); and
												</p>
												<p>
													(p) a reference to a{" "}
													<b>regulation</b> includes
													any regulation, rule,
													official directive, request
													or guideline (whether or not
													having the force of law) of
													any governmental,
													inter-governmental or
													supranational body, agency,
													department or regulatory,
													self-regulatory or other
													authority or organisation.
												</p>
											</ul>
										</div>
										<div>
											<h3>2. Guarantee and indemnity</h3>
											<p>
												2.1 In consideration of the
												Lender making or continuing
												loans, giving credit or granting
												banking facilities, or granting
												accommodation or time to the
												Borrower as the Lender in its
												absolute discretion sees fit,
												the Guarantor guarantees to the
												Lender, whenever the Borrower
												does not pay any of the
												Guaranteed Obligations when due,
												to pay on demand the Guaranteed
												Obligations.
											</p>
											<p>
												2.2 The Guarantor, as principal
												obligor and as a separate and
												independent obligation and
												liability from his or her
												obligations and liabilities
												under 2.1, agrees to indemnify
												the Lender and keep the Lender
												indemnified in full and on
												demand from and against all and
												any losses, costs, claims,
												liabilities, damages, demands
												and expenses suffered or
												incurred by the Lender arising
												out of, or in connection with,
												the Guaranteed Obligations not
												being recoverable for any reason
												or any failure of the Borrower
												to perform or discharge any of
												its obligations or liabilities
												in respect of the Guaranteed
												Obligations.
											</p>
										</div>
										<div>
											<h3>3. Lender protections</h3>
											<p>
												3.1 This guarantee is and shall
												at all times be a continuing
												security and shall cover the
												ultimate balance from time to
												time owing to the Lender by the
												Borrower in respect of the
												Guaranteed Obligations.
											</p>
											<p>
												3.2 The liability of the
												Guarantor under this guarantee
												shall not be reduced, discharged
												or otherwise adversely affected
												by:
											</p>
											<ul>
												<p>
													(a) any intermediate
													payment, settlement of
													account or discharge in
													whole or in part of the
													Guaranteed Obligations;
												</p>
												<p>
													(b) any variation,
													extension, discharge,
													compromise, dealing with,
													exchange or renewal of any
													right or remedy which the
													Lender may now or after the
													date of this guarantee have
													from or against any of the
													Borrower and any other
													person in connection with
													the Guaranteed Obligations;
												</p>
												<p>
													(c) any act or omission by
													the Lender or any other
													person in taking up,
													perfecting or enforcing any
													Security, indemnity, or
													guarantee from or against
													the Borrower or any other
													person;
												</p>
												<p>
													(d) any termination,
													amendment, variation,
													novation, replacement or
													supplement of or to any of
													the Guaranteed Obligations
													[including, without
													limitation, any change in
													the purpose of, any increase
													in or extension of the
													Guaranteed Obligations and
													any addition of new
													Guaranteed Obligations];
												</p>
												<p>
													(e) any grant of time,
													indulgence, waiver or
													concession to the Borrower
													or any other person;
												</p>
												<p>
													(f) the insolvency,
													bankruptcy, liquidation,
													administration or winding
													up, or any incapacity,
													limitation, disability,
													discharge by operation of
													law or change in the
													constitution, name or style,
													of the Borrower, the Lender
													or any other person;
												</p>
												<p>
													(g) the death or incapacity
													(whether mental or physical)
													of the Guarantor, or any
													notice of his or her death
													or incapacity;
												</p>
												<p>
													(h) any invalidity,
													illegality,
													unenforceability,
													irregularity or frustration
													of any actual or purported
													obligation of, or Security
													held from, the Borrower or
													any other person in
													connection with the
													Guaranteed Obligations;
												</p>
												<p>
													(i) any claim or enforcement
													of payment from the Borrower
													or any other person;
												</p>
												<p>
													(j) any act or omission
													which would not have
													discharged or affected the
													liability of the Guarantor
													had he or she been a
													principal debtor instead of
													a guarantor; or
												</p>
												<p>
													(k) any other act or
													omission except an express
													written release [by deed] of
													the Guarantor by the Lender.
												</p>
											</ul>
											<p>
												3.3 The Lender shall not be
												obliged, before taking steps to
												enforce any of its rights and
												remedies under this guarantee,
												to:
											</p>
											<ul>
												<p>
													(a) take any action or
													obtain judgment in any court
													against the Borrower or any
													other person;
												</p>
												<p>
													(b) make or file any claim
													in a bankruptcy,
													liquidation, administration
													or insolvency of the
													Borrower or any other
													person; or
												</p>
												<p>
													(c) make demand or enforce
													or seek to enforce any
													claim, right or remedy
													against the Borrower or any
													other person.
												</p>
											</ul>
											<p>
												3.4 he Guarantor warrants to the
												Lender that he or she has not
												taken, exercised or received,
												and shall not take, exercise or
												receive, any Rights from or
												against the Borrower, its
												liquidator, an administrator, a
												co-guarantor or any other person
												in connection with any liability
												of, or payment by, the Guarantor
												under this guarantee but:
											</p>
											<ul style={{ listStyle: "none" }}>
												<p>
													(a) if any such Right is
													taken, exercised or received
													by the Guarantor, that Right
													and all monies at any time
													received or held in respect
													of that Right shall be held
													by the Guarantor on trust
													for the Lender for
													application in or towards
													the discharge of the
													Guaranteed Obligations under
													this guarantee; and
												</p>
												<p>
													(b) on demand by the Lender,
													the Guarantor shall promptly
													transfer, assign or pay to
													the Lender all other Rights
													and all monies from time to
													time held on trust by the
													Guarantor under this{" "}
													<b>3.4</b> .
												</p>
											</ul>
											<p>
												3.5 This guarantee is in
												addition to and shall not affect
												nor be affected by or merge with
												any other judgement, Security,
												right or remedy obtained or held
												by the Lender from time to time
												for the discharge and
												performance of the Borrower of
												the Guaranteed Obligations.
											</p>
										</div>
										<div>
											<h3>4. Interest</h3>
											<p>
												4.1 The Guarantor shall pay
												interest to the Lender on all
												sums demanded under this
												guarantee from the date of
												demand by the Lender or, if
												earlier, the date on which the
												relevant damages, losses, costs
												or expenses arose in respect of
												which the demand by the Lender
												has been made, until, but
												excluding, the date of actual
												payment.
											</p>
											<p>
												Interest under <b>4.1 </b> shall
												accrue on a day-to-day basis and
												be calculated by the Lender on
												such terms as the Lender may
												from time to time determine.
											</p>
										</div>
										<div>
											<h3>5. Costs</h3>
											<p>
												The Guarantor shall, within five
												Business Days of demand, pay to,
												or reimburse, the Lender, on a
												full indemnity basis, all costs,
												charges, expenses, taxes and
												liabilities of any kind
												(including, without limitation,
												legal, printing and
												out-of-pocket expenses) incurred
												by the Lender in connection
												with:
											</p>
											<ul>
												<p>
													(a) the negotiation,
													preparation, execution and
													delivery of this guarantee;
												</p>
												<p>
													(b) the preservation,
													exercise or enforcement of
													any rights under or in
													connection with this
													guarantee or any attempt to
													do so;
												</p>
												<p>
													(c) any amendment,
													extension, waiver or consent
													(or any proposal for any of
													these) under or in
													connection with this
													guarantee;
												</p>
												<p>
													(d) any discharge or release
													of this guarantee; or
												</p>
												<p>
													(e) any stamping or
													registration of this
													guarantee.
												</p>
											</ul>
										</div>
										<div>
											<h3>
												6. Representations and
												warranties
											</h3>
											<p>
												6.1 The Guarantor makes the
												representations and warranties
												set out in this Clause 6 to the
												Lender on the date of this
												guarantee and the
												representations and warranties
												contained in this Clause 6 are
												deemed to be repeated by the
												Guarantor on each day whilst
												this guarantee is still
												subsisting by reference to the
												facts and circumstances existing
												at the time of repetition.
											</p>
											<p>6.2 The Guarantor:</p>
											<ul>
												<p>
													(a) has the capacity to
													execute, deliver and perform
													his or her obligations under
													this guarantee and the
													transactions contemplated by
													them;
												</p>
												<p>
													(b) is not dead or, by
													reason of illness or
													incapacity (whether mental
													or physical), incapable of
													managing his or her own
													affairs; and
												</p>
												<p>
													(c) has not been and is not
													the subject of an order, and
													has not had and does not
													have a deputy appointed in
													respect of him or her, under
													any law of the Federal
													Republic of Nigeria (as
													amended and supplemented
													from time to time) which
													relates to mental capacity.
												</p>
											</ul>
											<p>
												6.3 The entry into, delivery and
												performance of the obligations
												in this guarantee do not and
												will not contravene or conflict
												with any law or regulation or
												judicial or official order
												applicable to the Guarantor or
												any agreement or instrument
												binding on the Guarantor or his
												or her assets or constitute a
												default or termination event
												(however described) under such
												agreement or instrument.
											</p>
											<p>
												6.4 The Guarantor has obtained
												all required or desirable
												authorisations to enable him or
												her to enter into, deliver and
												comply with his or her
												obligations under this guarantee
												and to make this guarantee
												admissible in evidence in
												Nigeria. Any such authorisations
												are in full force and effect.
											</p>
											<p>
												6.5 Subject to any general
												principles of law limiting
												obligations, the
												Guarantor&apos;s obligations
												under this guarantee are legal,
												valid, binding and enforceable.
											</p>
											<p>
												6.6 No litigation, arbitration
												or administrative proceedings
												are taking place or pending or,
												to the best of the
												Guarantor&apos;s knowledge and
												belief (after due and careful
												enquiry), have been threatened
												against him or her or any of his
												or her assets which, if
												adversely determined, might
												reasonably be expected to have a
												material adverse effect on the
												ability of the Guarantor to
												perform his or her obligations
												under this guarantee.
											</p>
											<p>
												6.7 None of the Guarantor&apos;s
												assets is entitled to immunity
												on any grounds from any legal
												action or proceeding (including,
												without limitation, suit,
												attachment prior to judgement,
												execution or other enforcement).
											</p>
											<p>
												6.8 No event or circumstance is
												outstanding which constitutes
												(or, with the expiry of a grace
												period, the giving of notice,
												the making of any determination
												or any combination thereof,
												would constitute) a default or
												termination event (however
												described) under any agreement
												or instrument which is binding
												on the Guarantor or to which any
												of his or her assets is subject
												which has or is likely to have a
												material adverse effect on the
												Guarantor&apos;s ability to
												perform his or her obligations
												under this guarantee.
											</p>
											<p>
												6.9 The Guarantor&apos;s payment
												obligations under this guarantee
												rank at least pari passu with
												all existing and future
												unsecured and unsubordinated
												obligations, except for those
												mandatorily preferred by law
												generally.
											</p>
											<p>6.10 The Guarantor:</p>
											<ul>
												<p>
													(a) has not suspended, or
													threatened to suspend,
													payment of his or her debts,
													is not unable to pay his or
													her debts as they fall due,
													has not admitted inability
													to pay his or her debts and
													is not deemed either unable
													to pay his or her debts or
													as having no reasonable
													prospect of so doing, in
													either case within the
													meaning of any insolvency
													law or regulation in Nigeria
													(as amended and supplemented
													from time to time);
												</p>
												<p>
													(b) has not commenced
													negotiations with all or any
													class of his or her
													creditors with a view to
													rescheduling any of his or
													her debts, and has not made
													a proposal for or entered
													into any compromise or
													arrangement with his or her
													creditors; and
												</p>
												<p>
													(c) is not the subject of a
													bankruptcy petition,
													application or order under
													Nigerian law (as amended and
													supplemented from time to
													time).
												</p>
												<p>
													6.11 No person has become
													entitled to appoint a
													receiver over any of the
													assets of the Guarantor, and
													no receiver has been
													appointed over any of the
													assets of the Guarantor.
												</p>
												<p>
													6.12 No creditor or
													encumbrancer has attached or
													taken possession of, and no
													distress, execution,
													sequestration or other such
													process has been levied or
													enforced on or sued against,
													any of the Guarantor&apos;s
													assets.
												</p>
												<p>
													6.13 No event has occurred
													and no proceeding has been
													taken in any jurisdiction to
													which the Guarantor is
													subject which has an effect
													equivalent or similar to any
													of the events mentioned in
													<b>6.10</b> to <b>6.12.</b>
												</p>
												<p>
													6.14 The choice of Nigerian
													law as the governing law of
													this guarantee will be
													recognised and enforced in
													the jurisdiction of the
													Guarantor&apos;s domicile
													and any judgment obtained in
													Nigeria in relation to this
													guarantee will be recognised
													and enforced in that
													jurisdiction.
												</p>
												<p>
													6.15 The Guarantor is not
													required under the law of
													his or her domicile to make
													any deduction for, or on
													account of, Tax from any
													payment he or she may make
													under this guarantee.
												</p>
												<p>
													6.16 Under the law of the
													Guarantor&apos;s domicile it
													is not necessary to file,
													record or enrol this
													guarantee with any court or
													other authority in that
													jurisdiction or pay any
													stamp, registration or
													similar Taxes in relation to
													this guarantee.
												</p>
											</ul>
										</div>
										<div>
											<h3>7. Accounts</h3>
											<p>
												7.1 The Lender may place to the
												credit of a suspense account any
												monies received under or in
												connection with this guarantee
												in order to preserve the rights
												of the Lender to prove for the
												full amount of all its claims
												against the Borrower or any
												other person in respect of the
												Guaranteed Obligations.
											</p>
											<p>
												7.2 The Lender may at any time
												and from time to time apply all
												or any monies held in any
												suspense account in or towards
												satisfaction of any of the
												monies, debts and liabilities
												that are the subject of this
												guarantee as the Lender, in its
												absolute discretion, may
												conclusively determine.
											</p>
											<p>
												7.3 If this guarantee ceases for
												any reason whatsoever to be
												continuing, the Lender may open
												a new account or new accounts in
												the name of the Borrower.
											</p>
											<p>
												7.4 If the Lender does not open
												a new account or new accounts in
												accordance with <b> 7.3</b>, it
												shall nevertheless be treated as
												if it had done so at the time
												when this guarantee ceased to be
												continuing, whether by
												termination, calling in or
												otherwise, in relation to the
												Borrower.
											</p>
											<p>
												7.5 As from the time of opening
												or deemed opening of a new
												account or new accounts, all
												payments made to the Lender by
												or on behalf of the Borrower
												shall be credited or be treated
												as having been credited to the
												new account or accounts and
												shall not operate to reduce the
												amount for which this guarantee
												is available at that time, nor
												shall the liability of the
												Guarantor under this guarantee
												be in any manner reduced or
												affected by any subsequent
												transactions, receipts or
												payments.
											</p>
										</div>
										<div>
											<h3>8. Termination</h3>
											<p>
												8.1 The Guarantor may terminate
												this guarantee by notice to the
												Lender with effect from the date{" "}
												<b>(Termination Date)</b>{" "}
												specified in that notice, which
												shall not be less than three
												calendar months after the notice
												is actually received by the
												Lender.
											</p>
											<p>
												8.2 Notwithstanding any notice
												of termination given under 8.1,
												the liability of the Guarantor
												under this guarantee shall
												continue in full force and
												effect in relation to:
											</p>
											<ul>
												<p>
													(a) all Guaranteed
													Obligations which have
													become due on or before the
													Termination Date; and
												</p>
												<div>
													(b) all Guaranteed
													Obligations which become
													due, owing or incurred by
													the Borrower to the Lender
													in relation to any
													transaction, dealing,
													commitment or other
													engagement entered into or
													effected either:
													<ul>
														<p>
															(i) before the
															Termination Date; or
														</p>
														<p>
															(ii) on or after the
															Termination Date
															pursuant to any
															commitment, express
															or implied, assumed
															or undertaken by the
															Lender to the
															Borrower before the
															Termination Date.
														</p>
													</ul>
												</div>
											</ul>
										</div>
										<div>
											<h3>9. Discharge conditional</h3>
											<p>
												9.1 Any release, discharge or
												settlement between the Guarantor
												and the Lender in relation to
												this guarantee shall be deemed
												conditional on no right,
												Security, disposition or payment
												given or made to the Lender by
												the Guarantor, the Borrower or
												any other person in respect of
												the Guaranteed Obligations being
												avoided, reduced or ordered to
												be refunded under or pursuant to
												any enactment or law relating to
												breach of duty by any person,
												insolvency, bankruptcy, winding
												up, administration, protection
												from creditors generally or
												receivership or for any other
												reason.
											</p>
											<p>
												9.2 If any right, Security,
												disposition or payment referred
												to in
												<b> 9.1</b> is avoided, reduced
												or ordered to be refunded, the
												Lender shall be entitled
												subsequently to enforce this
												guarantee against the Guarantor
												as if the release, discharge or
												settlement referred to in
												<b>9.1</b> had not occurred and
												such right, Security,
												disposition or payment had not
												been given or made.
											</p>
										</div>
										<div>
											<h3>10. Payments</h3>
											<p>
												10.1 All sums payable by the
												Guarantor under this guarantee
												shall be paid in the currency in
												which the Guaranteed Obligations
												are payable in full, without
												set-off, counterclaim or
												condition, and free and clear of
												and without any deduction or
												withholding whatsoever, provided
												that, if the Guarantor is
												required by law or regulation to
												make such deduction or
												withholding, he or she shall:
											</p>
											<ul>
												<p>
													(a) ensure that the
													deduction or withholding
													does not exceed the minimum
													amount legally required;
												</p>
												<p>
													(b) pay to the relevant
													taxation or other
													authorities, as appropriate,
													the full amount of the
													deduction or withholding;
												</p>
												<p>
													(c) give to the Lender,
													within the period for
													payment permitted by the
													relevant law, either:
												</p>
												<ul>
													<p>
														(i) an official receipt
														of the relevant taxation
														authorities concerned on
														payment to them of
														amounts so deducted or
														withheld; or
													</p>
													<p>
														(ii) if the taxation
														authorities concerned do
														not issue such receipts
														on payment to them of
														amounts so deducted or
														withheld, a certificate
														of deduction or
														equivalent evidence of
														the relevant deduction
														or withholding; and
													</p>
												</ul>
												<p>
													(d) pay to the Lender such
													additional amount as is
													necessary to ensure that the
													net full amount received by
													the Lender after the
													required deduction or
													withholding is equal to the
													amount that the Lender would
													have received had no such
													deduction or withholding
													been made.
												</p>
											</ul>
											<p>
												10.2 The Guarantor shall not and
												may not direct the application
												by the Lender of any sums
												received by the Lender from the
												Guarantor under any of the terms
												of this guarantee.
											</p>
										</div>
										<div>
											<h3>11. Assignment and transfer</h3>
											<p>
												11.1 At any time, without the
												consent of the Guarantor, the
												Lender may assign any of its
												rights or transfer any of its
												obligations under this
												guarantee.
											</p>
											<p>
												11.2 The Lender may disclose to
												any actual or proposed assignee
												or transferee any information in
												its possession that relates to
												the Guarantor and this guarantee
												that the Lender considers
												appropriate.
											</p>
											<p>
												11.3 The Guarantor may not
												assign any of his or her rights,
												or transfer any of his or her
												rights or obligations, under
												this guarantee or enter into any
												transaction which would result
												in any of those rights or
												obligations passing to another
												person.
											</p>
										</div>
										<div>
											<h3>
												12. Lender&apos;s right of
												set-off
											</h3>
											<p>
												12.1 The Lender may at any time
												set off any liability of the
												Guarantor to the Lender against
												any liability of the Lender to
												the Guarantor, whether either
												liability is present or future,
												liquidated or unliquidated, and
												whether or not either liability
												arises under this guarantee. If
												the liabilities to be set off
												are expressed in different
												currencies, the Lender may
												convert either liability at a
												market rate of exchange for the
												purpose of set-off. Any exercise
												by the Lender of its rights
												under this 12.1 shall not limit
												or affect any other rights or
												remedies available to it under
												this guarantee or otherwise.
											</p>
											<p>
												12.2 The Lender is not obliged
												to exercise its rights under
												<b>12.1.</b> If it does exercise
												those rights it must promptly
												notify the Guarantor of the
												set-off that has been made.
											</p>
										</div>
										<div>
											<h3>
												13. Evidence of amounts and
												certificates
											</h3>
											<p>
												Any certificate, determination
												or notification by the Lender as
												to a rate or any amount payable
												under this guarantee shall, in
												the absence of manifest error,
												be conclusive evidence of the
												matter to which it relates.
											</p>
										</div>
										<div>
											<h3>
												14. Amendments, waivers and
												consents
											</h3>
											<p>
												14.1 No amendment of this
												guarantee shall be effective
												unless it is in writing and
												signed by, or on behalf of, each
												party (or its authorised
												representative).
											</p>
											<p>
												14.2 A waiver of any right or
												remedy under this guarantee or
												by law, or any consent given
												under this guarantee, is only
												effective if given in writing by
												the waiving or consenting party
												and shall not be deemed a waiver
												of any subsequent right or
												remedy. It only applies to the
												circumstances in relation to
												which it is given and shall not
												prevent the party giving it from
												subsequently relying on the
												relevant provision.
											</p>
											<p>
												14.3 A failure by the Lender to
												exercise, or delay by it in
												exercising, any right or remedy
												provided under this guarantee or
												by law shall not constitute a
												waiver of that or any other
												right or remedy, prevent or
												restrict any further exercise of
												that or any other right or
												remedy or constitute an election
												to affirm this guarantee. No
												single or partial exercise of
												any right or remedy provided
												under this guarantee or by law
												shall prevent or restrict the
												further exercise of that or any
												other right or remedy. No
												election to affirm this
												guarantee by the Lender shall be
												effective unless it is in
												writing.
											</p>
											<p>
												14.4 The rights and remedies
												provided under this guarantee
												are cumulative and are in
												addition to, and not exclusive
												of, any rights and remedies
												provided by law.
											</p>
										</div>
										<div>
											<h3>15. Partial invalidity</h3>
											<p>
												If, at any time, any provision
												of this guarantee is or becomes
												illegal, invalid or
												unenforceable in any respect
												under any law of any
												jurisdiction, neither the
												legality, validity or
												enforceability of the remaining
												provisions nor the legality,
												validity or enforceability of
												such provision under the law of
												any other jurisdiction will in
												any way be affected or impaired.
											</p>
										</div>
										<div>
											<h3>16. Counterparts</h3>
											<p>
												16.1 This guarantee may be
												executed in any number of
												counterparts, each of which when
												executed and delivered shall
												constitute a duplicate original,
												but both counterparts shall
												together constitute one deed.
											</p>
											<p>
												16.2 Transmission of an executed
												counterpart of this guarantee
												(but for the avoidance of doubt
												not just a signature page) by
												e-mail (in PDF, JPEG or other
												agreed format) shall take effect
												as delivery of an executed
												counterpart of this guarantee.
												If either method of delivery is
												adopted, without prejudice to
												the validity of the deed thus
												made, each party shall provide
												the other with the original of
												such counterpart as soon as
												reasonably possible thereafter.
											</p>
										</div>
										<div>
											<h3>17. Third party rights</h3>
											<p>
												17.1 Except as expressly
												provided elsewhere in this
												guarantee, a person who is not a
												party to this guarantee has no
												rights to enforce, or enjoy the
												benefit of, any term of this
												guarantee.
											</p>
											<p>
												17.2 Notwithstanding any term of
												this guarantee, the consent of
												any person who is not a party to
												this guarantee is not required
												to rescind or vary this deed at
												any time.
											</p>
										</div>
										<div>
											<h3>Notices</h3>
											<p>
												18.1 <b>Delivery</b>
											</p>
											<p>
												Any notice or other
												communication given to a party
												under or in connection with this
												guarantee shall be:
											</p>
											<ul>
												<p>(a) in writing;</p>
												<p>
													(b) delivered by hand, by
													pre-paid first-class post or
													other next working day
													delivery service or sent by
													fax; and
												</p>
												<p>(c) sent to:</p>
												<ul>
													<p>
														(i) the Guarantor, per
														details provided in the
														Signature Block to this
														Guarantee and Indemnity
														and any other details
														provided by the
														Guarantor from time to
														time.
													</p>
													<p>(ii)the Lender at:</p>
													<p>
														5SD8 Lakeview Homes,
														Phase 2, Kado, Abuja.{" "}
														<br />
														Attention: Bidemi
														Adebayo <br />
														or to any other address
														or fax number notified
														in writing by one party
														to the other from time
														to time.
													</p>
												</ul>
											</ul>
											<p>
												18.2 <b>Receipt by Guarantor</b>
											</p>
											<p>
												Any notice or other
												communication that the Lender
												gives to the Guarantor shall be
												deemed to have been received:
											</p>
											<ul>
												<p>
													(a) if delivered by hand, at
													the time it is left at the
													relevant address;
												</p>
												<p>
													(b) if posted by pre-paid
													first-class post or other
													next working day delivery
													service, at 9.00 am on the
													second Business Day after
													posting; and
												</p>
												<p>
													(c) if sent by fax, when
													received in legible form. A
													notice or other
													communication given as
													described in <b>18.2(a) </b>{" "}
													or
													<b>18.2(c)</b> on a day that
													is not a Business Day, or
													after normal business hours,
													in the place it is received,
													shall be deemed to have been
													received at 9.00 am on the
													next Business Day. For the
													purposes of this clause, all
													references to time are to
													local time in the place of
													deemed receipt.
												</p>
											</ul>
											<p>
												18.3 <b>Receipt by Lender</b>
											</p>
											<p>
												Any notice or other
												communication given to the
												Lender shall be deemed to have
												been received only on actual
												receipt.
											</p>
											<p>
												18.4{" "}
												<b>Service of proceedings</b>
											</p>
											<p>
												This clause does not apply to
												the service of any proceedings
												or other documents in any legal
												action or, where applicable, any
												arbitration or other method of
												dispute resolution.
											</p>
											<p>
												18.5 <b>Notice by e-mail</b>
											</p>
											<p>
												A notice or other communication
												given under or in connection
												with this guarantee is valid if
												sent by e-mail.
											</p>
										</div>
										<div>
											<h3>
												19. Governing law and
												jurisdiction
											</h3>
											<p>
												19.1 <b>Governing law</b>
											</p>
											<p>
												This guarantee and any dispute
												or claim (including a
												non-contractual dispute or
												claim) arising out of or in
												connection with it or its
												subject-matter or formation
												shall be governed by, and
												construed in accordance with,
												the law of the Federal Republic
												of Nigeria.
											</p>
											<p>
												19.2 <b>Jurisdiction</b>
											</p>
											<p>
												Each party irrevocably agrees
												that, subject as provided below,
												any dispute, controversy,
												proceeding, or claim arises out
												of or in connection with or
												relating to this guarantee, the
												Lender shall use its reasonable
												endeavours to resolve such
												disputes, where it however
												remains unresolved, it shall be
												subject to arbitration in line
												with the Arbitration and
												Mediation Act 2023 of the Laws
												of the Federation of Nigeria (as
												amended and supplemented from
												time to time) (&quot;the
												Act&quot;) or any statutory
												re-enactment or modification
												thereof. Nothing in this clause
												shall limit the right of the
												Lender to take proceedings
												against the Guarantor in any
												other court of competent
												jurisdiction, arbitration
												tribunal. Nor shall the taking
												of proceedings in any one or
												more jurisdictions preclude the
												taking of proceedings in any
												other jurisdictions, whether
												concurrently or not, to the
												extent permitted by the law of
												such other jurisdiction.
											</p>
											<p>
												19.3 The arbitration will be
												conducted in Abuja, Nigeria
												except as otherwise agreed by
												parties. The cost of arbitration
												filing fees and appointing, and
												arbitrator is to be agreed upon
												and borne by both parties. Any
												judgment on the award rendered
												by the Arbitrator shall be
												written and shall be binding on
												the parties and may be entered
												as a judgment in any Court of
												Competent Jurisdiction. This
												however doesn&apos;t preclude
												the Lender from seeking
												injunctive or other equitable
												relief from the courts as
												necessary to prevent the actual
												or threatened infringement,
												misappropriation, dilution, or
												violation of our data security,
												intellectual property rights, or
												other proprietary rights.
											</p>
											<p>
												19.4 Guarantor further agrees
												that any proceedings to resolve
												any disputes shall be done
												solely on an individual basis
												and that the Guarantor will not
												seek to have any dispute heard
												as a class action, a
												representative action, a
												collective action, a private
												attorney-general action, or in
												any proceeding in which the
												Guarantor acts or proposes to
												act in a representative
												capacity.
											</p>
											<p>
												19.5 Guarantor also agrees that
												no proceeding will be joined,
												consolidated, or combined with
												another proceeding without the
												prior written consent of the
												Lender and all parties to any
												such proceeding. All costs,
												expenses and expenditures
												including, without limitation,
												the complete legal costs
												incurred by enforcing this
												Agreement as a result of any
												default by the Guarantor, will
												be added to the principal sum
												then outstanding and will
												immediately be paid by the
												Guarantor.
											</p>
											<p>
												19.6 <b>Other service</b>
											</p>
											<p>
												The Guarantor irrevocably
												consents to any process in any
												proceedings under <b>19.2</b> to{" "}
												<b>19.5</b> being served on it
												in accordance with the
												provisions of this guarantee
												relating to service of notices.
												Nothing contained in this
												guarantee shall affect the right
												to serve process in any other
												manner permitted by law.
											</p>
											<p>
												The terms of this Guarantee and
												Indemnity shall remain in full
												force and effect until such time
												as the Borrower has fully
												discharged all outstanding
												liabilities to the Lender. Upon
												the Borrower having no further
												obligations to the Lender, the
												Guarantor may submit a written
												request (in respect of which
												email will suffice) to the
												Lender for release from its
												obligations under this Guarantee
												and Indemnity. The Lender shall
												promptly release the Guarantor
												from its obligations, provided
												that all liabilities of the
												Borrower have been satisfied in
												full.
											</p>
										</div>
										<p>
											This document has been executed as a
											deed and is delivered and takes
											effect on the date stated in the
											signature block executed by the
											Guarantor.
										</p>
										<div>
											<strong>
												I, the undersigned, hereby
												acknowledge and agree that I
												have read and understood the
												terms of this Guarantee and
												Indemnity, which I am providing
												on behalf of the Borrower, and
												for the benefit of Hadi Africa
												Limited until such time as the
												Borrower&apos;s obligations to
												Hadi Africa Limited ceases:
											</strong>
										</div>
									</section>
								</div>
							)}
						</div>
						<div className="flex justify-between space-x-6">
							<Button
								disabled={declineIsLoading || acceptLoading}
								onClick={handleDeclineCredit}
								additionalClasses="rounded-md bg-transparent border font-bold py-2 font-normal text-[#DB2719] border-[#DB2719] flex-1 text-sm"
							>
								{declineIsLoading ? (
									<Loader2 />
								) : (
									"Not Interested"
								)}
							</Button>
							<Button
								disabled={declineIsLoading || acceptLoading}
								additionalClasses="rounded-md border border-[#BABFC3] text-[14px] text-[#202223] flex-1 text-white"
								type="submit"
							>
								{acceptLoading ? (
									<Loader2 color="#061F6A" />
								) : (
									"Agree"
								)}
							</Button>
						</div>
					</div>
				</>
			) : null}
		</>
	);
};

export default GuarantorFormStep;
