import { useEffect } from "react";
import { MdClose } from "react-icons/md";
import { Briefcase, LogoutCurve, Sun1, Moon } from "iconsax-react";
import { AnimatePresence, motion } from "framer-motion";
import Logo from "../../assets/image/Logo.svg";
import SidebarItem from "./SidebarItem";
import { useLogoutUser } from "../../hooks/useAuthData";
import { useSidebar } from "../../provider/SidebarViewProvider";
// import useDimension from "../../hooks/useDimension";
import {
	dashBoardSidebarData,
	myCompanySidebarData,
	myAccountSidebarData,
} from "./SidebarData";
import { useTheme } from "../theme-provider";

export const Sidebar: React.FC = () => {
	const { theme, setTheme } = useTheme();
	// const { height } = useDimension();
	const { showSidebar, setShowSidebar } = useSidebar();
	const { mutate: logoutUser } = useLogoutUser();

	const toggleTheme = () => {
		// setTheme(theme === "dark" ? "light" : "dark");
		setTheme("light");
	};

	const handleLogout = () => {
		logoutUser();
	};

	useEffect(() => {
		const handleResize = () => {
			if (window.innerWidth > 1024) setShowSidebar(true);
		};
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return (
		<AnimatePresence>
			{showSidebar && (
				<motion.div
					className={`${
						showSidebar ? "fixed" : "hidden"
					}  top-0 z-50 h-full w-[286px] flex-shrink-0 overflow-x-hidden bg-primary-900 lg:relative`}
					initial={{ x: "-100%" }}
					animate={{ x: "0%" }}
					exit={{ x: "-100%" }}
					transition={{
						duration: 0.3,
						type: "tween",
						ease: "easeInOut",
					}}
				>
					<div className="h-full w-[286px] ">
						<div className="h-full min-h-0 flex-col">
							<div className="relative h-full w-full flex-1 items-start">
								<nav className="flex h-full flex-col pr-1.5">
									<div className="sticky top-0 z-50 flex items-center justify-between bg-primary-900 px-6 py-[26px] lg:justify-center">
										<img
											src={Logo}
											alt=""
											className="h-8 w-24"
										/>
										<button
											className="rounded-full p-2 text-xl text-white duration-200 hover:bg-white hover:bg-opacity-30 lg:hidden"
											onClick={(e) => {
												e.preventDefault();
												setShowSidebar(!showSidebar);
											}}
										>
											{showSidebar && <MdClose />}
										</button>
									</div>
									<div className="scrollbar-trigger -mr-0.5 flex-1 flex-col overflow-y-auto pr-2 transition-opacity duration-500">
										<div className="flex flex-1 flex-col ">
											<div className="font-size-[14px] mt-6 flex flex-col px-6 ">
												<p className="mb-2 px-4 text-[14px] font-medium capitalize text-primary-200">
													Main Menu
												</p>

												{dashBoardSidebarData.map(
													(menuItem) => (
														<SidebarItem
															key={menuItem.id}
															icon={menuItem.icon}
															activeIcon={
																menuItem.activeIcon
															}
															title={
																menuItem.title
															}
															path={menuItem.path}
															submenus={
																menuItem.submenus
															}
														/>
													)
												)}
											</div>
											<div
												className={`font-size-[14px] mt-12 flex flex-col px-6 `}
											>
												<p className="mb-2 px-4 text-[14px] font-medium capitalize  text-primary-200">
													TracPad
												</p>

												{myCompanySidebarData.map(
													(menuItem) => (
														<SidebarItem
															key={menuItem.id}
															icon={menuItem.icon}
															activeIcon={
																menuItem.activeIcon
															}
															title={
																menuItem.title
															}
															path={menuItem.path}
															submenus={
																menuItem?.submenus
															}
														/>
													)
												)}
												{/* <hr className="mt-2" /> */}
											</div>

											<div
												className={`font-size-[14px] mt-12 flex flex-col px-6`}
											>
												<p className="mb-2 px-4 text-[14px] font-medium capitalize  text-primary-200">
													My Account
												</p>
												{myAccountSidebarData.map(
													(menuItem) => (
														<SidebarItem
															key={menuItem.id}
															icon={menuItem.icon}
															activeIcon={
																menuItem.activeIcon
															}
															title={
																menuItem.title
															}
															path={menuItem.path}
															submenus={
																menuItem?.submenus
															}
														/>
													)
												)}
												<button onClick={toggleTheme}>
													<div className="flex items-center gap-3">
														<SidebarItem
															icon={
																<Briefcase
																	size="22"
																	variant="Outline"
																/>
															}
															title="Dark Mode"
														/>
														<div className="flex w-[68px] items-center gap-1 rounded-full bg-white p-1 focus:outline-none">
															<div
																className={`flex h-7 w-7 items-center justify-center rounded-full ${
																	theme ===
																	"light"
																		? "bg-primary-900"
																		: "bg-white"
																} p-1`}
															>
																<Sun1
																	color={`${
																		theme ===
																		"light"
																			? "#ffffff"
																			: "#1A202C"
																	}`}
																/>
															</div>
															<div
																className={`flex h-7 w-7 items-center justify-center rounded-full ${
																	theme ===
																	"dark"
																		? "bg-primary-900"
																		: "bg-white"
																}  p-1`}
															>
																<Moon
																	color={`${
																		theme ===
																		"dark"
																			? "#ffffff"
																			: "#1A202C"
																	}`}
																/>
															</div>
														</div>
													</div>
												</button>
											</div>
										</div>
									</div>
									<div className="mb-20 mt-auto px-6 pt-12">
										<button
											onClick={handleLogout}
											className="text-red-500"
										>
											<SidebarItem
												icon={
													<LogoutCurve
														size="22"
														variant="Outline"
													/>
												}
												title="Log Out"
												colorClass="text-red-500 font-medium"
											/>
										</button>
									</div>
								</nav>
							</div>
						</div>
					</div>
				</motion.div>
			)}
		</AnimatePresence>
	);
};
