import React from "react";
import { Button } from "../../../../components";

const GuarantorFormSuccess: React.FC = () => {
	const closeWindow = () => {
		window.location.href = "https://hadifinance.com";
	};
	return (
		<>
			<div className="flex w-full max-w-[640px] flex-col items-end justify-center">
				<Button
					disabled={false}
					onClick={closeWindow}
					additionalClasses="text-red-500 underline bg-transparent w-auto leading"
				>
					Close
				</Button>
			</div>
			<div className="mt-14 flex h-full w-full max-w-[640px] justify-between space-x-6">
				<div className="w-full">
					<div className="mt-[15px] w-full">
						<h3 className="text-center text-2xl font-medium leading-7 text-[#1A4393]">
							Guarantor form submitted!
						</h3>
						<p className="mt-2 text-center text-[#90A3BF]">
							Your form has been submitted successfully and is now
							being processed.
						</p>
					</div>
					<div className="flex items-center justify-center py-8">
						<svg
							width="300"
							height="231"
							viewBox="0 0 300 231"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M147.101 97.6858C122.675 126.426 59.1006 136.809 35.7613 159.397"
								stroke="#1A202C"
								strokeLinecap="round"
								strokeDasharray="20.39 13.5 13.55 20.39 13.5 13.55"
							/>
							<path
								d="M146.203 138.613C119.103 166.78 58.2709 180.556 34.8632 200.324"
								stroke="#1A202C"
								strokeLinecap="round"
								strokeDasharray="33.95 11.9 41.84 33.95 11.9 41.84"
							/>
							<path
								d="M150.938 119.857L151.183 119.648L151.094 119.339L143.027 91.1628L257.667 36.0244L171.372 154.875L139.614 129.533L150.938 119.857Z"
								fill="white"
								stroke="#1A202C"
							/>
							<path
								d="M150.938 119.857L151.183 119.648L151.094 119.339L143.027 91.1628L256.658 36.5099L151.039 138.521L139.617 129.53L150.938 119.857Z"
								fill="#BAE5FF"
								stroke="#1A202C"
							/>
							<path
								d="M142.43 90.895L150.613 119.477L138.829 129.546L171.469 155.593L259.236 34.7152L142.43 90.895Z"
								stroke="#1A202C"
								strokeLinejoin="round"
							/>
							<path
								d="M258.309 35.2503L107.901 158.001L123.726 114.35L224.94 50.3308L224.45 49.4607L144.645 89.2179L181.135 49.0145L258.309 35.2503Z"
								fill="#3D81DB"
								stroke="#1A202C"
							/>
							<path
								d="M180.879 48.5522L142.487 90.8516L224.673 49.9083L123.314 114.018L106.813 159.534L260.117 34.4199L180.879 48.5522Z"
								stroke="#1A202C"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</div>

					<Button
						disabled={false}
						type="button"
						onClick={closeWindow}
						additionalClasses="text-lg text-white font-medium bg-primary-800 mt-[14px] lg:mt-[27px] col-span-2"
					>
						Close Page
					</Button>
				</div>
			</div>
		</>
	);
};

export default GuarantorFormSuccess;
