import { useEffect, useState, Fragment } from "react";
import {
	Button,
	FormikInput,
	Loader2,
	PasswordChangeSuccessful,
} from "../components";
import blackLogo from "../assets/image/blackLogo.svg";
import { passwordResetConfirmData } from "../types/hooks/auth";
import { usePasswordResetConfirm } from "../hooks/useAuthData";
import { Toast } from "../utils/toast";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { AxiosError } from "axios";
import { ErrorResponse } from "@/types/general";

const ResetPassword = () => {
	const location = useLocation();
	const [show, setShow] = useState(false);
	const navigate = useNavigate();
	const [resetData, setResetData] = useState<passwordResetConfirmData>({
		new_password1: "",
		new_password2: "",
		uid: "",
		token: "",
	});

	useEffect(() => {
		const { pathname } = location;
		const uuid = pathname.split("/")[2];
		const token = pathname.split("/")[3];
		if (!token || !uuid) {
			return navigate("/login");
		}

		setResetData((prevData) => ({
			...prevData,
			uid: uuid,
			token: token,
		}));
	}, [location, navigate]);

	const passwordResetOnSuccess = () => {
		setShow(true);
	};

	const passwordResetOnError = (error: AxiosError) => {
		const errorMessage =
			(error?.response?.data as ErrorResponse)?.message ||
			"Please, try again!";
		if (errorMessage == "Invalid value") {
			navigate("/forgot-password");
			return Toast.error(
				"Password Reset Token Expired. Please request a new one."
			);
		}
		Toast.error(errorMessage);
	};

	const { mutate: resetPassword, isLoading } = usePasswordResetConfirm(
		passwordResetOnError,
		passwordResetOnSuccess
	);

	const handleReset = (payload: passwordResetConfirmData) => {
		resetPassword(payload);
	};

	return (
		<Fragment>
			<div className="item-center flex w-full justify-between mxs:flex-col mxs:items-center">
				<figure className="block h-8 w-28 lg:hidden">
					<img src={blackLogo} alt="Hadi logo" />
				</figure>
				<Link
					to="/register"
					className="flex w-full cursor-pointer items-center justify-end text-right text-[16px] font-[500] text-[#334155] mxs:justify-center mxs:text-center"
				>
					Don&apos;t have an account?{" "}
					<span className="ml-1 cursor-pointer text-[#002EA9]">
						Sign up
					</span>
				</Link>
			</div>
			<div className="relative mx-auto flex w-full flex-1 items-center justify-center">
				<div className="flex h-full w-full flex-col items-center justify-center px-3">
					<div className="min-h-[450px] w-full max-w-[500px]">
						<h3 className="w-full text-left text-[32px] font-[600] leading-[1] text-primary-900 lg:leading-[56px]">
							Reset your password
						</h3>
						<p className="test-[14px] mb-[63px] font-[400] text-secondary-400">
							New password must be different from previous one
						</p>
						<Formik
							enableReinitialize
							initialValues={resetData}
							validationSchema={Yup.object({
								new_password1: Yup.string()
									.required("Password is required")
									.min(
										8,
										"Password must be at least 8 characters"
									),
								new_password2: Yup.string()
									.oneOf(
										[Yup.ref("new_password1"), null],
										"Passwords must match"
									)
									.required("Confirm password is required"),
							})}
							onSubmit={handleReset}
						>
							{({ values }) => (
								<Form className="flex w-full flex-col gap-9">
									<FormikInput
										label="New Password"
										floatingLabel={true}
										name="new_password1"
										id="new_password1"
										type="password"
										value={values.new_password1}
										placeholder="Enter new Password"
										disabled={isLoading}
										additionalClasses="border-[#263238] placeholder-[#263238]"
										autoComplete="off"
										password={true}
										required={true}
									/>

									<FormikInput
										label="Confirm Password"
										floatingLabel={true}
										name="new_password2"
										id="new_password2"
										type="password"
										disabled={isLoading}
										value={values.new_password2}
										placeholder="Confirm Password"
										additionalClasses="border-[#263238] placeholder-[#263238]"
										autoComplete="off"
										required={true}
									/>

									<Button
										disabled={isLoading}
										additionalClasses={
											"text-lg text-white font-medium bg-primary-800 mt-[14px] lg:mt-[27px]"
										}
										type="submit"
									>
										{isLoading ? (
											<Loader2 />
										) : (
											"Reset Password"
										)}
									</Button>
								</Form>
							)}
						</Formik>
					</div>
				</div>
			</div>
			<PasswordChangeSuccessful show={show} setShow={setShow} />
		</Fragment>
	);
};

export default ResetPassword;
