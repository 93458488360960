import { AxiosError, AxiosResponse } from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
	createBeneficiary,
	createPIN,
	updatePIN,
	verifyOtp,
	deleteBeneficiary,
	getAccountBeneficiaries,
	getAccountDetails,
	getPoolerWalletDetails,
	getAccountLookup,
	getAccountTransactionHistory,
	getAccountTransactions,
	getAllBanks,
	makeTransaction,
	createPaymentCode,
	// getChangeTransactionPinOTP,
} from "../api/wallet";
import {
	BeneficiaryData,
	TransferData,
} from "../components/Dashboard/HadiPay/BankTransfer";
import { Toast } from "../utils/toast";

export const useGetAllBanks = (
	onSuccess: (data: AxiosResponse) => void = () => {
		return;
	},
	onError: (error: AxiosError) => void
) => {
	return useQuery("all_banks", getAllBanks, {
		refetchInterval: Infinity,
		onSuccess,
		onError,
	});
};

export const useGetAccountLookup = (
	account_number: string,
	bank_code: string,
	onSuccess: (data: AxiosResponse) => void = () => {
		return;
	},
	onError: (error: AxiosError) => void
) => {
	return useQuery(
		["all_banks", account_number, bank_code],
		getAccountLookup,
		{
			staleTime: 0,
			cacheTime: 0,
			refetchInterval: false,
			refetchOnWindowFocus: false,
			enabled: account_number.length === 10 && bank_code.length > 0,
			onSuccess,
			onError: (error: AxiosError) => {
				onError(error);
				switch (error?.response?.status) {
					case 409:
						Toast.error(
							"Bank details do not match, kindly select another bank"
						);
						break;

					default:
						Toast.error("An error occured 🤕, kindly try again");
						break;
				}
			},
		}
	);
};

export const useMakeTransaction = (
	onSuccess: (data: AxiosResponse) => void = () => {
		return;
	},
	onError: (error: AxiosError) => void
) => {
	const mutation = useMutation((formData) => makeTransaction(formData), {
		onError: (error: AxiosError) => {
			onError(error);
			switch (error?.response?.status) {
				case 403:
					Toast.error("Transaction could not be completed");
					break;
				case 401:
					Toast.error("Invalid Transaction Pin");
					break;
				default:
					break;
			}
		},
		onMutate: (formData: TransferData) => {
			return formData;
		},
		onSuccess,
	});

	return { mutateMakeTransaction: mutation.mutate, ...mutation };
};

export const useGetAccountInfo = (
	onSuccess: (data: AxiosResponse) => void = () => {
		return;
	},
	onError: (error: AxiosError) => void
) => {
	return useQuery("hadi_pay_account_details", getAccountDetails, {
		onSuccess,
		onError,
	});
};

export const useGetPoolerWalletInfo = (
	onSuccess: (data: AxiosResponse) => void = () => {
		return;
	},
	onError: (error: AxiosError) => void,
	enabled: boolean
) => {
	return useQuery("pooler_wallet_details", getPoolerWalletDetails, {
		onSuccess,
		onError,
		enabled: enabled,
	});
};

export const useGetAccountBeneficiaries = (
	onSuccess: (data: AxiosResponse) => void = () => {
		return;
	},
	onError: (error: AxiosError) => void
) => {
	return useQuery("account_beneficiaries", getAccountBeneficiaries, {
		onSuccess,
		onError,
	});
};

export const useGetAccountTransactionHistory = (
	onSuccess: (data: AxiosResponse) => void = () => {
		return;
	},
	onError: (error: AxiosError) => void
) => {
	return useQuery("transaction_history", getAccountTransactionHistory, {
		onSuccess,
		onError,
	});
};

export const useGetAccountTransactions = (
	onSuccess: (data: AxiosResponse) => void = () => {
		return;
	},
	onError: (error: AxiosError) => void
) => {
	return useQuery("transactions", getAccountTransactions, {
		onSuccess,
		onError,
	});
};

export const useCreateBeneficiary = (
	onSuccess: (data: AxiosResponse) => void = () => {
		return;
	},
	onError: (error: AxiosError) => void
) => {
	const queryClient = useQueryClient();
	const mutation = useMutation(createBeneficiary, {
		onError,
		onMutate: (formData: BeneficiaryData) => {
			return formData;
		},
		onSuccess: (data: AxiosResponse) => {
			onSuccess(data);
			queryClient.invalidateQueries("account_beneficiaries");
		},
	});

	return { mutateCreateBeneficiary: mutation.mutate, ...mutation };
};

export const useCreatePIN = (
	onSuccess: (data: AxiosResponse) => void = () => {
		return;
	},
	onError: (error: AxiosError) => void
) => {
	const mutation = useMutation(createPIN, {
		onError,
		onMutate: (data: { pin: string }) => {
			return data;
		},
		onSuccess: (data: AxiosResponse) => {
			onSuccess(data);
		},
	});

	return { mutateCreatePIN: mutation.mutate, ...mutation };
};

export const useUpdatePIN = (
	onSuccess: (data: AxiosResponse) => void = () => {
		return;
	},
	onError: (error: AxiosError) => void
) => {
	const mutation = useMutation(updatePIN, {
		onError,
		onMutate: (data: {
			new_pin: string;
			otp_pin_id: string;
			otp_pin: string;
		}) => {
			return data;
		},
		onSuccess: (data: AxiosResponse) => {
			onSuccess(data);
		},
	});

	return { mutateUpdatePIN: mutation.mutate, ...mutation };
};

export const useVerifyUpdateOTP = (
	onSuccess: (data: AxiosResponse) => void = () => {
		return;
	},
	onError: (error: AxiosError) => void
) => {
	const mutation = useMutation(verifyOtp, {
		onError,
		onMutate: (data: { pin: string }) => {
			return data;
		},
		onSuccess: (data: AxiosResponse) => {
			onSuccess(data);
		},
	});

	return { mutateVerifyUpdateOTP: mutation.mutate, ...mutation };
};

export const useDeleteBeneficiary = (
	onSuccess: (data: AxiosResponse) => void = () => {
		return;
	},
	onError: (error: AxiosError) => void
) => {
	const queryClient = useQueryClient();
	const mutation = useMutation(deleteBeneficiary, {
		onError,
		onMutate: (account_number: string) => {
			return account_number;
		},
		onSuccess: (data: AxiosResponse) => {
			onSuccess(data);
			queryClient.invalidateQueries("account_beneficiaries");
		},
	});

	return { ...mutation };
};

export const useCreatePaymentCode = (
	onSuccess: (data: AxiosResponse) => void = () => {
		return;
	},
	onError: (error: AxiosError) => void
) => {
	const mutation = useMutation(createPaymentCode, {
		onSuccess: (data: AxiosResponse) => {
			onSuccess(data);
		},
		onError,
	});
	return { ...mutation };
};
// export const useGetChangeTransactionPinOTP = (
// 	onSuccess: (data: AxiosResponse) => void = () => {
// 		return;
// 	},
// 	onError: (error: AxiosError) => void
// ) => {
// 	return useQuery("change_transaction_pin_otp", getChangeTransactionPinOTP, {
// 		onSuccess,
// 		onError,
// 	});
// };
