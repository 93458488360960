import { getDueDate } from "../utils/utilities";

export const BACKEND_URI = process.env.REACT_APP_API_URI;
export const API_VERSION = process.env.REACT_APP_API_VERSION;
export const MONO_KEY = process.env.REACT_APP_MONO_PUBLIC_KEY;
export const SENTRY_KEY = process.env.REACT_APP_SENTRY_DSN;
export const GOOGLE_ANALYTICS_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;
export const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN;

export const API_URI = `${BACKEND_URI}/${API_VERSION}`;

export const BusinessSector = [
	{ value: "Test 1", label: "Pharmaceutical" },
	{ value: "Test 2", label: "Energy" },
	{ value: "FMCG", label: "FMCG" },
];

export const RepaymentDate = [
	// { value: "7", label: "7-Day Loan" },
	{ value: "14", label: "14-Day Loan" },
];
//Change to distributorCreditLoanPeriod on next iteration
export const CreditLoanPeriod = [
	// { value: "7", label: "7-Day Loan" },
	{ value: "14", label: "14-Day Loan" },
	{ value: "30", label: "30-Day Loan" },
];

export const RetailerCreditLoanPeriod = [
	// { value: "7", label: "7-Day Loan" },
	{ value: "14", label: "14-Day Loan" },
	{ value: "21", label: "21-Day Loan(Pharmaceuticals Only)" },
	{ value: "28", label: "28-Day Loan(Pharmaceuticals Only)" },
];

export const NextOfKinRelationship = [
	{ value: "parents", label: "Parents" },
	{ value: "sibling", label: "Sibling" },
	{ value: "relative", label: "Relative" },
	{ value: "other", label: "Other" },
];
/**The constant below was not modified as it related to guarantor(not sure if it would break)
 *  the value is meant to be sibling and not siblings but t
 */
export const Relationship = [
	{ value: "parents", label: "Parents" },
	{ value: "sibling", label: "Sibling" },
	{ value: "relative", label: "Relative" },
	{ value: "other", label: "Other" },
];

export const EmploymentStatus = [
	{ value: "employed", label: "Employed" },
	{ value: "unemployed", label: "Unemployed" },
];
export const OccupationType = [
	{ value: "salary_earner", label: "Salary Earner" },
	{ value: "self_employed", label: "Business Owner" },
];

export const IDType = [
	{
		label: "International Passport",
		value: "international_passport",
	},
	{
		label: "Driver's License",
		value: "drivers_license",
	},
	{
		label: "Voter's Card",
		value: "voter_card",
	},
	{
		label: "National ID",
		value: "national_id",
	},
];
export const SalaryRange = [
	{ value: "30,000 - 90,000", label: "30,000 - 90,000" },
	{ value: "90,000 - 160,000", label: "90,000 - 160,000" },
	{ value: "160,000 - 250,000", label: "160,000 - 250,000" },
	{ value: "250,000 - 370,000", label: "250,000 - 370,000" },
	{ value: "600,000 - 1,000,000", label: "600,000 - 1,000,000" },
];

export const PermissionLevel = [
	{ value: "User Level 1 Permission", label: "User Level 1 Permission" },
	{ value: "User Level 2 Permission", label: "User Level 2 Permission" },
	{ value: "User Level 3 Permission", label: "User Level 3 Permission" },
];

export const Gender = [
	{ value: "M", label: "Male" },
	{ value: "F", label: "Female" },
];

export const CRMValues = [
	{ value: "quick_book", label: "Quick Book" },
	{ value: "number", label: "Number" },
	{ value: "safe_book", label: "Safe Book" },
];

export const Patterns = {
	telephone: /^\d{11}$/,
	password: /^(?=.*[\W_]).{8,20}$/,
	// eslint-disable-next-line no-useless-escape
	email: /^([a-z\d\.-_]+)@([a-z\d-]+)\.([a-z]{2,8})(\.[a-z]{2,8})?(\.[a-z]{2,8})?(\.[a-z]{2,8})?$/,
};

export const supportedExtensions: string[] = [
	"jpeg",
	"jpg",
	"png",
	"gif",
	"mp4",
	"pdf",
	"psd",
	"ai",
	"doc",
	"docx",
	"ppt",
	"pptx",
];

// an object that has all bank names in nigeria
export const BankNames = [
	{ value: "044", label: "Access Bank" },
	{ value: "063", label: "Access Bank (Diamond)" },
	{ value: "035A", label: "ALAT by WEMA" },
	{ value: "401", label: "ASO Savings and Loans" },
	{ value: "50931", label: "Bowen Microfinance Bank" },
	{ value: "50823", label: "CEMCS Microfinance Bank" },
	{ value: "023", label: "Citibank Nigeria" },
	{ value: "050", label: "Ecobank Nigeria" },
	{ value: "562", label: "Ekondo Microfinance Bank" },
	{ value: "070", label: "Fidelity Bank" },
	{ value: "011", label: "First Bank of Nigeria" },
	{ value: "214", label: "First City Monument Bank" },
	{ value: "00103", label: "Globus Bank" },
	{ value: "058", label: "Guaranty Trust Bank" },
	{ value: "50383", label: "Hasal Microfinance Bank" },
	{ value: "030", label: "Heritage Bank" },
	{ value: "301", label: "Jaiz Bank" },
	{ value: "082", label: "Keystone Bank" },
	{ value: "50211", label: "Kuda Bank" },
	{ value: "565", label: "One Finance" },
	{ value: "327", label: "Paga" },
	{ value: "526", label: "Parallex Bank" },
	{ value: "100004", label: "PayCom" },
	{ value: "076", label: "Polaris Bank" },
	{ value: "101", label: "Providus Bank" },
	{ value: "125", label: "Rubies MFB" },
	{ value: "51310", label: "Sparkle Microfinance Bank" },
	{ value: "221", label: "Stanbic IBTC Bank" },
	{ value: "068", label: "Standard Chartered Bank" },
	{ value: "232", label: "Sterling Bank" },
	{ value: "100", label: "Suntrust Bank" },
	{ value: "302", label: "TAJ Bank" },
	{ value: "51211", label: "TCF MFB" },
	{ value: "102", label: "Titan Trust Bank" },
	{ value: "032", label: "Union Bank of Nigeria" },
	{ value: "033", label: "United Bank For Africa" },
	{ value: "215", label: "Unity Bank" },
	{ value: "566", label: "VFD" },
	{ value: "035", label: "Wema Bank" },
	{ value: "057", label: "Zenith Bank" },
];

export const creditFiltersDate = () => {
	const today = new Date();
	// Three days interval
	let threeDaysAgo: string | Date = new Date(today);
	threeDaysAgo.setDate(today.getDate() - 3);
	threeDaysAgo = getDueDate(threeDaysAgo);

	// 1 week interval
	let oneWeekAgo: string | Date = new Date(today);
	oneWeekAgo.setDate(today.getDate() - 7);
	oneWeekAgo = getDueDate(oneWeekAgo);

	// 2 weeks interval
	let twoWeekAgo: string | Date = new Date(today);
	twoWeekAgo.setDate(today.getDate() - 14);
	twoWeekAgo = getDueDate(twoWeekAgo);

	return [
		{ value: threeDaysAgo, label: threeDaysAgo + "- now" },
		{ value: oneWeekAgo, label: oneWeekAgo + "- now" },
		{ value: twoWeekAgo, label: twoWeekAgo + "- now" },
		{ value: "3-days tenure", label: "3-days tenure" },
		{ value: "7-days tenure", label: "7-days tenure" },
		{ value: "14-days tenure", label: "14-days tenure" },
	];
};

export const statusFiltersDate = [
	{
		value: "applied",
		label: "Applied",
	},
	{
		value: "authorized",
		label: "Authorized",
	},
	{
		value: "processing",
		label: "Processing",
	},
	{
		value: "approved",
		label: "Approved",
	},
	{
		value: "rejected",
		label: "Rejected",
	},
	{
		value: "accepted",
		label: "Accepted",
	},
	{
		value: "declined",
		label: "Declined",
	},
	{
		value: "delivered",
		label: "Delivered",
	},
	{
		value: "disbursed",
		label: "Disbursed",
	},
	{
		value: "payment_due",
		label: "Payment due",
	},
	{
		value: "pending_payment",
		label: "Pending payment",
	},
	{
		value: "defaulted",
		label: "Defaulted",
	},
	{
		value: "cancelled",
		label: "Cancelled",
	},
];

export enum NotificationType {
	Info = "info",
	Success = "success",
	Warning = "warning",
	Error = "error",
}

export enum NotificationType2 {
	Tick = "tick",
	Profile = "profile",
	Date = "date",
	Status = "status",
	Dispatch = "dispatch",
	Disbursed = "disbursed",
}

export enum NotificationType3 {
	CreditCancelled = "Credit Cancelled",
	CreditAuthorized = "Credit Authorized",
	CreditApplied = "Credit Applied",
	CreditProcessing = "Credit Processing",
}

export enum TransactionHistory {
	Incoming = "incoming",
	Outcoming = "outcoming",
}

export const ERPData = [
	{ value: "quick_book", label: "Quick Book" },
	{ value: "zoho", label: "Zoho" },
];

export const MaritalStatus = [
	{ value: "married", label: "Married" },
	{ value: "single", label: "Single" },
	{ value: "divorced", label: "Divorced" },
];

export const States = [
	{ value: "Abia", label: "Abia" },
	{ value: "Adamawa", label: "Adamawa" },
	{ value: "AkwaIbom", label: "AkwaIbom" },
	{ value: "Anambra", label: "Anambra" },
	{ value: "Bauchi", label: "Bauchi" },
	{ value: "Bayelsa", label: "Bayelsa" },
	{ value: "Benue", label: "Benue" },
	{ value: "Borno", label: "Borno" },
	{ value: "Cross River", label: "Cross River" },
	{ value: "Delta", label: "Delta" },
	{ value: "Ebonyi", label: "Ebonyi" },
	{ value: "Edo", label: "Edo" },
	{ value: "Ekiti", label: "Ekiti" },
	{ value: "Enugu", label: "Enugu" },
	{ value: "FCT", label: "FCT - Abuja" },
	{ value: "Gombe", label: "Gombe" },
	{ value: "Imo", label: "Imo" },
	{ value: "Jigawa", label: "Jigawa" },
	{ value: "Kaduna", label: "Kaduna" },
	{ value: "Kano", label: "Kano" },
	{ value: "Katsina", label: "Katsina" },
	{ value: "Kebbi", label: "Kebbi" },
	{ value: "Kogi", label: "Kogi" },
	{ value: "Kwara", label: "Kwara" },
	{ value: "Lagos", label: "Lagos" },
	{ value: "Nasarawa", label: "Nasarawa" },
	{ value: "Niger", label: "Niger" },
	{ value: "Ogun", label: "Ogun" },
	{ value: "Ondo", label: "Ondo" },
	{ value: "Osun", label: "Osun" },
	{ value: "Oyo", label: "Oyo" },
	{ value: "Plateau", label: "Plateau" },
	{ value: "Rivers", label: "Rivers" },
	{ value: "Sokoto", label: "Sokoto" },
	{ value: "Taraba", label: "Taraba" },
	{ value: "Yobe", label: "Yobe" },
	{ value: "Zamfara", label: "Zamfara" },
];

export const Nationalities = [{ value: "nigerian", label: "Nigerian" }];
