import { ProcessUploadFileProps } from "types/components/cards";
import { supportedExtensions } from "../../services/Constants";
import React, { useRef, useState } from "react";
import { SlCloudUpload } from "react-icons/sl";
import { Toast } from "../../utils/toast";
import { useField, useFormikContext } from "formik";
import {
	updateNestedState,
	convertToCommaSeparatedString,
} from "../../utils/utilities";

interface ProcessUploadFilePropsWithFormik
	extends Omit<ProcessUploadFileProps, "setFile"> {
	name?: string;
	setFile?: ProcessUploadFileProps["setFile"];
}

interface BaseProps extends ProcessUploadFilePropsWithFormik {
	updateFileState?: (fileData: string | null, fileName: string) => void;
	clearFileState?: () => void;
}

// Separate component for Formik functionality
const FormikUploadFile: React.FC<ProcessUploadFilePropsWithFormik> = ({
	formats,
	localStorageFileName,
	maxSize,
	name,
}) => {
	const [field, _, helpers] = useField(name!);
	const formik = useFormikContext();

	return (
		<ProcessUploadFileBase
			formats={formats}
			localStorageFileName={localStorageFileName}
			maxSize={maxSize}
			updateFileState={(fileData: string | null, fileName: string) => {
				helpers.setValue(fileData);
				formik.setFieldValue(`${localStorageFileName}Name`, fileName);
			}}
			clearFileState={() => {
				helpers.setValue(null);
				formik.setFieldValue(`${localStorageFileName}Name`, "");
			}}
			name={name}
		/>
	);
};

// Base component without Formik integration
const ProcessUploadFileBase: React.FC<BaseProps> = ({
	formats,
	localStorageFileName,
	maxSize,
	name,
	setFile,
	updateFileState,
	clearFileState,
}) => {
	const filePickerRef = useRef<HTMLInputElement>(null);
	const [isHovered, setIsHovered] = useState(false);
	const [isDragging, setIsDragging] = useState(false);

	const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		setIsDragging(true);
	};

	const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		setIsDragging(true);
	};

	const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		setIsDragging(false);
	};

	const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		setIsDragging(false);

		const files = e.dataTransfer.files;
		if (!files) return;
		if (files && files.length > 0) {
			handleDroppedFiles(files);
		}
	};

	const setFileToState = (fileData: string | null, fileName: string) => {
		if (setFile) {
			setFile((prevState: any) => {
				const updatedStateWithFileData = updateNestedState(
					prevState,
					localStorageFileName,
					fileData
				);
				const updatedStateWithFileName = updateNestedState(
					updatedStateWithFileData,
					`${localStorageFileName}Name`,
					fileName
				);
				return updatedStateWithFileName;
			});
		}
	};

	const handleFileState = (fileData: string | null, fileName: string) => {
		if (updateFileState) {
			updateFileState(fileData, fileName);
		} else {
			setFileToState(fileData, fileName);
		}
	};

	const handleClearState = () => {
		if (clearFileState) {
			clearFileState();
		} else if (setFile) {
			setFile((prevFile: any) => ({
				...prevFile,
				[localStorageFileName]: null,
				[localStorageFileName + "Name"]: "",
			}));
		}
	};

	const checkFileSizeAndExtension = (file: File) => {
		if (file.size > maxSize) {
			handleClearState();
			Toast.info(
				`This file size is greater than ${(
					maxSize /
					1024 /
					1024
				).toFixed(0)}MB`
			);
			return;
		}

		const fileName = file?.name;
		const fileExtension = fileName?.split(".").pop()?.toLowerCase();

		if (
			!(
				formats
					? convertToCommaSeparatedString(formats)
					: supportedExtensions
			).includes(fileExtension || "")
		) {
			handleClearState();
			Toast.info("." + fileExtension + " format is not supported");
			return;
		}

		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => {
			const fileData = reader.result as string;
			handleFileState(fileData, file.name);
		};
	};

	const addDocument = (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files?.[0];
		if (!file) return;
		checkFileSizeAndExtension(file);

		if (filePickerRef.current) {
			filePickerRef.current.value = "";
		}
	};

	const handleDroppedFiles = (files: FileList) => {
		const droppedFile = Array.from(files).filter((file) => {
			const fileExtension = file.name.split(".").pop()?.toLowerCase();
			return (
				formats
					? convertToCommaSeparatedString(formats)
					: supportedExtensions
			).includes(fileExtension || "");
		});

		if (droppedFile.length > 0) {
			checkFileSizeAndExtension(droppedFile[0]);
		} else {
			Toast.info("Invalid file format");
		}
	};

	return (
		<div
			className={`bg-clearblue mt-2 flex h-fit w-full cursor-pointer select-none flex-col items-center justify-center rounded-xl border border-dashed border-[#384EB74D] bg-[#F1F5F9] py-5 duration-200 ease-in-out ${
				isHovered ? "bg-blue-100" : ""
			} ${isDragging ? "bg-blue-200" : ""}`}
			onClick={() => filePickerRef.current?.click()}
			onDragOver={handleDragOver}
			onDragEnter={handleDragEnter}
			onDragLeave={handleDragLeave}
			onDrop={handleDrop}
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
		>
			<SlCloudUpload className="min-h-[56px] min-w-[64px] text-[#002060]" />
			<input
				type="file"
				ref={filePickerRef}
				name={name || "inputfile"}
				hidden
				onChange={addDocument}
				id="inputfile"
				accept={formats ? convertToCommaSeparatedString(formats) : ""}
			/>
			<div className="mt-2 flex flex-col gap-1.5 text-center">
				<p className="text-[14px] font-[500] text-[#94A3B8] underline">
					Drag & drop files or{" "}
					<span className="text-[#002EA9]">Browse</span>
				</p>
				<p className="text-xs text-[#94A3B8]">
					Supported formats: {formats.join(", ").toUpperCase()}
				</p>
			</div>
		</div>
	);
};

// Main component that decides which version to render
export const ProcessUploadFile: React.FC<ProcessUploadFilePropsWithFormik> = ({
	name,
	...props
}) => {
	if (name) {
		return <FormikUploadFile name={name} {...props} />;
	}

	// For non-Formik usage, directly pass props to base component
	return <ProcessUploadFileBase {...props} />;
};

export default ProcessUploadFile;
