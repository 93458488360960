import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { initGA, logPageView } from "./analytics";

const RouteChangeTracker: React.FC = () => {
	const location = useLocation();

	useEffect(() => {
		initGA();
		logPageView();
	}, [location]);

	return null;
};

export default RouteChangeTracker;
